<template>
  <div>
    <div class="content-body">
      <div class="component-section no-code">
        <div class="container-fluid px-0">
          <div class="row">
            <div class="col-lg-12">
              <ul id="myTab" role="tablist" class="nav nav-tabs worker_tabs">
                <li class="nav-item">
                  <a
                    id="tab-1"
                    @click="changeTab('BASIC')"
                    data-toggle="tab"
                    href="#tab_1"
                    role="tab"
                    aria-controls="tab_1"
                    aria-selected="true"
                    class="nav-link active"
                    >Basic Details</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    id="tab-2"
                    @click="changeTab('LOAN')"
                    data-toggle="tab"
                    href="#tab_2"
                    role="tab"
                    aria-controls="tab_2"
                    aria-selected="false"
                    class="nav-link"
                    >Loan Details</a
                  >
                </li>
              </ul>
              <div id="myTabContent" class="tab-content">
                <div
                  id="tab_1"
                  role="tabpanel"
                  aria-labelledby="tab-1"
                  class="tab-pane fade active show"
                >
                  <!-- <div class="card rounded-5">
                    <div class="card-body p-3 p-lg-3">
                      <h5>Basic Details</h5>
                      <hr />
                      <div class="table-responsive" style="min-height: auto">
                        <table class="table table-primary mg-b-0">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Mobile Number</th>
                              <th>Address</th>
                              <th>Occupation</th>
                              <th>Level</th>
                              <th>Loan service</th>
                              <th>Branch</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{{ detail?.name }}</td>
                              <td>{{ detail?.mobile_number }}</td>
                              <td>{{ detail?.info?.address }} {{ detail?.info?.state?.name }}</td>
                              <td>{{ detail?.info?.occupation }}</td>
                              <td>{{ userInfo.level }}</td>
                              <td>{{ userInfo.loan_service }}</td>
                              <td>{{ detail?.branch?.name }}</td>
                              <td>
                                <a title="Edit" class="btn btn-brand-02 " style="cursor: pointer"
                                  @click="onUpdateUserInfo()"><img src="/static/img/edit-icon.svg" /> Edit</a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div> -->

                  <div class="col-lg-12 px-3">
                    <div class="card rounded-5" style="border-radius: 10px">
                      <div
                        style="border-bottom: 1px solid #ccc"
                        class="card-header pb-2 pt-3"
                      >
                        <h4 class="text-bolder">Basic Details</h4>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-lg-8">
                            <div class="row">
                              <div class="col-lg-4">
                                <h5 class="label-text">CIN Number</h5>
                              </div>
                              <div class="col-lg-8">
                                <h5 class="text-bolder">
                                  {{ detail?.cin_number }}
                                </h5>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-4">
                                <h5 class="label-text">Name</h5>
                              </div>
                              <div class="col-lg-8">
                                <h5 class="text-bolder">{{ detail?.name }}</h5>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-4">
                                <h5 class="label-text">Mobile Number</h5>
                              </div>
                              <div class="col-lg-8">
                                <h5 class="text-bolder">
                                  {{ detail?.mobile_number }}
                                </h5>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-4">
                                <h5 class="label-text">Gender</h5>
                              </div>
                              <div class="col-lg-8">
                                <h5 class="text-bolder">
                                  {{
                                    $helperService.getTitleCase(
                                      detail?.info?.gender
                                    )
                                  }}
                                </h5>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-4">
                                <h5 class="label-text">Date Of Birth</h5>
                              </div>
                              <div class="col-lg-8">
                                <h5 class="text-bolder">
                                  {{
                                    $helperService.getFormattedDateOnly(
                                      detail?.info?.date_of_birth
                                    )
                                  }}
                                </h5>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-lg-4">
                                <h5 class="label-text">Address</h5>
                              </div>
                              <div class="col-lg-8">
                                <h5 class="text-bolder">
                                  {{ detail?.info?.address }}
                                  {{ detail?.info?.state?.name }}
                                </h5>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-4">
                                <h5 class="label-text">Occupation</h5>
                              </div>
                              <div class="col-lg-8">
                                <h5 class="text-bolder">
                                  {{ detail?.info?.occupation?.title }}
                                </h5>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-lg-4">
                                <h5 class="label-text">Branch</h5>
                              </div>
                              <div class="col-lg-8">
                                <h5 class="text-bolder">
                                  {{ detail?.branch?.name }}
                                </h5>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-4">
                                <h5 class="label-text">Level</h5>
                              </div>
                              <div class="col-lg-8">
                                <h5 class="text-bolder">
                                  {{
                                    $helperService.getTitleCase(userInfo.level)
                                  }}
                                </h5>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-4">
                                <h5 class="label-text">Loan Service</h5>
                              </div>
                              <div class="col-lg-8">
                                <h5 class="text-bolder">
                                  {{
                                    $helperService.getTitleCase(
                                      userInfo.loan_service
                                    )
                                  }}
                                </h5>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-4">
                                <h5 class="label-text">
                                  Max Loan Approved Amount
                                </h5>
                              </div>
                              <div class="col-lg-8">
                                <h5 class="text-bolder">
                                  {{
                                    $helperService.getFormattedCurrency(
                                      max_approved_amount
                                    )
                                  }}
                                </h5>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="col-lg-4" style="cursor: pointer">
                              <div class="">
                                <h5 class="text-bolder">Photo</h5>
                                <img
                                  :src="
                                    detail.profile_image
                                      ? detail.profile_image
                                      : '/static/img/placeholder.png'
                                  "
                                  class="img-rounded"
                                  style="witdh: 100px; height: 100px"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="row">
                          <div class="col-lg-8">
                            <div class="row">
                              <div class="col-lg-4">
                                <h5 class="label-text">Name </h5>
                                <h5 class="label-text">Mobile Number</h5>
                                <h5 class="label-text">Address</h5>
                                <h5 class="label-text">Occupation</h5>
                                <h5 class="label-text">Branch</h5>
                                <h5 class="label-text">Level</h5>
                                <h5 class="label-text">Loan Service</h5>
                              </div>
                              <div class="col-lg-8">

                                <h5 class="text-bolder">{{ detail?.name }}</h5>
                                <h5 class="text-bolder">{{ detail?.mobile_number }}</h5>
                                <h5 class="text-bolder">{{ detail?.info?.address }} {{ detail?.info?.state?.name }}</h5>
                                <h5 class="text-bolder">{{ detail?.info?.occupation?.title }}</h5>
                                <h5 class="text-bolder">{{ detail?.branch?.name }}</h5>
                                <h5 class="text-bolder">{{ $helperService.getTitleCase(userInfo.level) }}</h5>
                                <h5 class="text-bolder">{{ $helperService.getTitleCase(userInfo.loan_service) }}</h5>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4" style="cursor:pointer">
                            <div class="">
                              <h5 class="text-bolder">Photo</h5>
                              <img :src="detail.profile_image ? detail.profile_image : '/static/img/placeholder.png'"
                                class="img-rounded" style="witdh:100px;height:100px" alt="">
                            </div>
                          </div>
                        </div> -->
                        <div class="row" v-if="access.can_update == 1">
                          <div class="col-md-12 text-center">
                            <a
                              title="Edit"
                              class="btn btn-brand-02"
                              style="cursor: pointer"
                              @click="onUpdateUserInfo()"
                              ><img src="/static/img/edit-icon.svg" /> Edit</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12 pt-3">
                    <div class="card rounded-2" style="border-radius: 10px">
                      <div
                        style="border-bottom: 1px solid #ccc"
                        class="card-header pb-2 pt-3"
                      >
                        <div class="row">
                          <div class="col-lg-6">
                            <h4 class="text-bolder">KYC Details</h4>
                          </div>
                          <!-- <div
                            v-if="permission == 1"
                            class="col-lg-6 text-right"
                          >
                            <a
                              href="javascript:void(0)"
                              @click="onUplaodDocument"
                            >
                              + Upload</a
                            >
                          </div> -->
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="table-responsive">
                              <table class="table table-primary mg-b-0">
                                <thead>
                                  <tr>
                                    <th scope="col">Document</th>
                                    <th scope="col">Document No.</th>
                                    <th scope="col">Updated By</th>
                                    <th scope="col">Updated At</th>
                                    <th scope="col" class="">Action</th>
                                  </tr>
                                </thead>
                                <tbody v-if="documentList.length > 0">
                                  <tr
                                    v-for="(item, i) in documentList"
                                    :key="item.id"
                                  >
                                    <td>{{ item?.title }}</td>
                                    <td>{{ item?.doc_no }}</td>
                                    <td>
                                      {{
                                        item?.added_by?.name
                                          ? item?.added_by?.name
                                          : "-"
                                      }}
                                    </td>
                                    <td>
                                      <span>
                                        {{
                                          $helperService.getFormattedDate(
                                            item?.updated_at
                                          )
                                        }}</span
                                      >
                                    </td>

                                    <td
                                      v-if="
                                        item.image_url != null ||
                                        item.image_url == ''
                                      "
                                      style="cursor: pointer"
                                    >
                                      <img
                                        :src="
                                          item.image_url
                                            ? item.image_url
                                            : '/static/img/placeholder.png'
                                        "
                                        class="img-rounded"
                                        style="width: 50px"
                                        alt=""
                                        v-on:click="
                                          openImage(item.image_url, i)
                                        "
                                      />
                                      <a
                                        href="javascript:void(0)"
                                        @click="editKycDocument(item)"
                                        class="mx-2"
                                        >Edit</a
                                      >

                                      <a
                                        v-if="item.image_url != null"
                                        href="javascript:void(0)"
                                        @click="
                                          downloadPdf(
                                            item.image_url,
                                            item.title
                                          )
                                        "
                                        class="mx-2"
                                        >Download</a
                                      >
                                    </td>
                                    <td v-else>-</td>
                                  </tr>
                                </tbody>
                                <template v-else>
                                  <tr>
                                    <td colspan="5">
                                      <div class="text-center m-5">
                                        <h3>Sorry,record not found.</h3>
                                      </div>
                                    </td>
                                  </tr>
                                </template>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="tab_2"
                  role="tabpanel"
                  aria-labelledby="tab-2"
                  class="tab-pane fade"
                >
                  <div class="card rounded-5">
                    <div class="card-body p-3 p-lg-3">
                      <div class="table-responsive">
                        <table class="table table-primary mg-b-0">
                          <thead>
                            <tr>
                              <th scope="col">Type</th>
                              <th
                                scope="col"
                                class="sorting"
                                @click="sorting($event, 'application_id')"
                              >
                                Loan #
                              </th>

                              <th
                                scope="col"
                                class="sorting"
                                @click="sorting($event, 'email')"
                              >
                                Loan Type
                              </th>
                              <th
                                scope="col"
                                class="sorting"
                                @click="sorting($event, 'email')"
                              >
                                Loan Amount
                              </th>
                              <th
                                scope="col"
                                class="sorting"
                                @click="sorting($event, 'created_at')"
                              >
                                Disbursed On
                              </th>
                              <th
                                scope="col"
                                class="sorting"
                                @click="sorting($event, 'status')"
                              >
                                Status
                              </th>
                              <th scope="col" class="">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="loader">
                              <td colspan="7" class="text-center">
                                <div
                                  class="spinner-border spinner-color"
                                  role="status"
                                ></div>
                              </td>
                            </tr>
                            <tr v-else v-for="item in list" :key="item.id">
                              <td>
                                {{
                                  $helperService.getTitleCase(
                                    item?.user_loan_relation
                                  )
                                }}
                              </td>
                              <td><a   style="text-decoration:underline; color:#005da9" href="javascript:void(0)" @click="commonFunctionForRedirect(item)" class="text-underline">{{ item?.application_id }}</a></td>
                              <td>
                                {{ item?.loan_type?.title }}
                                {{
                                  " (" +
                                  (item?.loan_type?.has_secured == 0
                                    ? "Unsecured)"
                                    : "Secured)")
                                }}
                                -
                                {{
                                  $helperService.getTitleCase(
                                    item?.loan_type?.level
                                  )
                                }}
                              </td>

                              <td>
                                {{
                                  $helperService.getFormattedCurrency(
                                    item?.loan_amount
                                  )
                                }}
                              </td>
                              <td v-if="item.disbursed_at">
                                {{
                                  $helperService.getFormattedDate(
                                    item.disbursed_at
                                  )
                                }}
                              </td>
                              <td v-else>-</td>
                              <td>
                                <span
                                  v-if="item.client_status == 'ONGOING'"
                                  style="color: #5cc82a"
                                >
                                  Active
                                </span>
                                <span
                                  v-if="item.client_status == 'PENDING'"
                                  style="color: #eeb82d"
                                  >{{
                                    $helperService.getTitleCase(
                                      item.client_status
                                    )
                                  }}</span
                                >
                                <span
                                  v-if="
                                    item.client_status == 'CANCELLED' ||
                                    item.client_status == 'REJECTED'
                                  "
                                  style="color: #ed1c24"
                                  >{{
                                    $helperService.getTitleCase(
                                      item.client_status
                                    )
                                  }}</span
                                >
                                <span
                                  v-if="item.client_status == 'CLOSED'"
                                  style="color: #ede91c"
                                  >{{
                                    $helperService.getTitleCase(
                                      item.client_status
                                    )
                                  }}</span
                                >
                              </td>
                              <td>
                                <a
                                  title="View Statement"
                                  class="mx-2"
                                  style="cursor: pointer"
                                  @click="view(item, access.can_update)"
                                  v-if="access.can_read == 1"
                                >
                                  <img src="/static/img/eye-icon.svg" alt=""
                                /></a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <ErrorComponent
                          @retry="getList(1)"
                          ref="LOAN_ERROR_COMPONENT"
                        />
                      </div>
                      <div class="row">
                        <div class="col-lg-12 mt-5 text-center">
                          <div class="page-bottom-pagination text-right">
                            <Pagination
                              @page-change="pageChange"
                              @items-per-page-change="itemsPerPageChange"
                              ref="LOAN_PAGINATION"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="updateUserInfo"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Update User Info</h5>
          <button
            type="button"
            class="close"
            @click="onCloseUpdateInfo()"
            aria-label="Close"
          >
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
        <Form
          @submit="onFinalUpdateInfo"
          class="columns column"
          ref="addressUpdate"
        >
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-12">
                <h4 style="border-bottom: 1px solid #cccccc; line-height: 2">
                  Basic Info
                </h4>
                <div class="row">
                  <div class="col-sm-6 mb-3 pt-2">
                    <label>
                      Name
                      <span class="text-danger">*</span></label
                    >
                    <Field
                      name="name"
                      id="name"
                      rules="required:name"
                      class="form-control"
                      v-model="users.name"
                      type="text"
                      v-bind:placeholder="'name'"
                    />
                    <ErrorMessage name="name" class="validation-msg" />
                  </div>
                  <div class="col-sm-6 mb-3 pt-2">
                    <label>
                      Mobile Number
                      <span class="text-danger">*</span></label
                    >
                    <Field
                      name="mobile_number"
                      id="mobile_number"
                      rules="required:mobile number"
                      class="form-control"
                      v-model="users.mobile_number"
                      type="text"
                      v-bind:placeholder="'Mobile Number'"
                    />
                    <ErrorMessage name="mobile_number" class="validation-msg" />
                  </div>
                  <div class="col-sm-6 mb-3 pt-2">
                    <label> Gender </label>
                    <div class="">
                      <span class="mr-3">
                        <input
                          class=""
                          v-model="users.gender"
                          :checked="users.gender == 'MALE' ? true : false"
                          type="radio"
                          value="MALE"
                          id="male"
                          style="width: 20px; height: auto"
                        />
                        <label class="form-check-label" for="male">
                          Male
                        </label>
                      </span>
                      <span>
                        <input
                          class=""
                          v-model="users.gender"
                          :checked="users.gender == 'FEMALE' ? true : false"
                          type="radio"
                          value="FEMALE"
                          id="female"
                          style="width: 20px; height: auto"
                        />
                        <label class="form-check-label" for="female">
                          Female
                        </label>
                      </span>
                    </div>

                    <ErrorMessage name="gender" class="validation-msg" />
                  </div>
                  <div class="col-sm-6 mb-4">
                    <label
                      >Date of Birth<span class="text-danger">*</span></label
                    >
                    <Field
                      name="date_of_birth"
                      class="form-control"
                      v-slot="{ date }"
                      rules="required:date of birth,true"
                      id="date_of_birth"
                      v-model="users.date_of_birth"
                    >
                      <VueDatePicker
                        v-bind="date"
                        placeholder="Select Date Of Birth "
                        style="height: 46px !important"
                        v-model="users.date_of_birth"
                        :type="boolean"
                        :format="'dd/MM/yyyy'"
                        :default="true"
                        :enable-time-picker="false"
                        :max-date="Date.now()"
                      >
                      </VueDatePicker>
                    </Field>
                    <ErrorMessage name="date_of_birth" class="validation-msg" />
                  </div>
                  <div class="col-sm-6 mb-3">
                    <label>
                      Occupation
                      <span class="text-danger">*</span></label
                    >
                    <Field
                      v-slot="{ field }"
                      name="occupation"
                      rules="required:occupation,true"
                      :validateOnInput="true"
                      v-model="occupation"
                    >
                      <Multiselect
                        v-bind="field"
                        rules="required:occupation,true"
                        ref="occupation_multiselect"
                        mode="single"
                        trackBy="title"
                        label="title"
                        valueProp="value"
                        placeholder="Select occupation"
                        class="form-control"
                        v-model="occupation"
                        :options="occupations"
                      />
                    </Field>
                    <ErrorMessage name="occupation" class="validation-msg" />
                  </div>
                  <div class="col-lg-6 mb-3">
                <label>Profession</label>
                <Field name="profession" class="form-control" :validateOnInput="true" v-model="users.profession"
                  type="text" autoComplete="off" placeholder="Profession" autocapitalize="false" />

              </div>
                </div>
                <h4 style="border-bottom: 1px solid #cccccc; line-height: 2">
                  Loan Config
                </h4>
                <div class="row">
                  <div class="col-sm-6 mb-3" v-if="user_type == 'SUPER_ADMIN'">
                    <label>
                      Level
                      <span class="text-danger">*</span></label
                    >
                    <Field
                      v-slot="{ field }"
                      name="tire"
                      rules="required:level,true"
                      :validateOnInput="true"
                      v-model="users.tier"
                    >
                      <select
                        v-model="users.tier"
                        class="form-control"
                        v-bind="field"
                      >
                        <option value="GOLD">Gold</option>
                        <option value="PLATINUM">Platinum</option>
                        <option value="SILVER">Silver</option>
                        <option value="BRONZE">Bronze</option>
                      </select>
                    </Field>
                    <ErrorMessage name="tire" class="validation-msg" />
                  </div>
                  <div class="col-lg-6 mb-4">
                    <label> Branch <span class="text-danger">*</span> </label>
                    <Field
                      v-slot="{ field }"
                      name="branch_id"
                      rules="required:branch,true"
                      :validateOnInput="true"
                      v-model="users.branch_id"
                    >
                      <Multiselect
                        v-bind="field"
                        ref="branch_id_multiselect"
                        trackBy="name"
                        rules="required"
                        label="name"
                        valueProp="uuid"
                        placeholder="select branch"
                        class="form-control"
                        v-model="users.branch_id"
                        :options="branchList"
                        :close-on-select="true"
                        :createOption="true"
                        :searchable="true"
                      />
                    </Field>
                    <ErrorMessage name="branch_id" class="validation-msg" />
                  </div>
                  <div class="col-lg-6 mb-4" v-if="user_type == 'SUPER_ADMIN'">
                    <label
                      >Enable Renew after # of Cycle<span class="text-danger"
                        >*</span
                      >
                    </label>
                    <Field
                      name="no_of_cycle_for_renew"
                      class="form-control"
                      rules="required:enable renew after cycle,true|numeric"
                      :validateOnInput="true"
                      v-model="users.no_of_cycle_for_renew"
                      type="number"
                      placeholder="Enable Renew after # of Cycle"
                      autocapitalize="false"
                    />
                    <ErrorMessage
                      name="no_of_cycle_for_renew"
                      class="validation-msg"
                    />
                  </div>
                  <div class="col-sm-6 mb-3" v-if="user_type == 'SUPER_ADMIN'">
                    <label>
                      Loan Service
                      <span class="text-danger">*</span></label
                    >
                    <Field
                      v-slot="{ field }"
                      name="loan_service"
                      rules="required:loan service,true"
                      :validateOnInput="true"
                      v-model="users.loan_service"
                    >
                      <select
                        v-model="users.loan_service"
                        class="form-control"
                        v-bind="field"
                      >
                        <option value="ALLOW">Allow</option>
                        <option value="DISALLOW">DIsallow</option>
                      </select>
                    </Field>
                    <ErrorMessage name="loan_service" class="validation-msg" />
                  </div>
                </div>
                <h4 style="border-bottom: 1px solid #cccccc; line-height: 2">
                  Address
                </h4>
                <div class="row">
                  <div class="col-sm-6 mb-3">
                    <label>
                      Address
                      <span class="text-danger">*</span></label
                    >
                    <Field
                      name="address"
                      id="address"
                      rules="required:address"
                      class="form-control"
                      v-model="users.address"
                      type="text"
                      v-bind:placeholder="'Address'"
                    />
                    <ErrorMessage name="address" class="validation-msg" />
                  </div>
                  <div class="col-sm-6 mb-3">
                    <label>
                      City
                      <span class="text-danger">*</span></label
                    >
                    <Field
                      name="city"
                      id="city"
                      rules="required:city"
                      class="form-control"
                      v-model="users.city"
                      type="text"
                      v-bind:placeholder="'City'"
                    />
                    <ErrorMessage name="city" class="validation-msg" />
                  </div>

                  <div class="col-sm-6 mb-3">
                    <label>
                      State
                      <span class="text-danger">*</span>
                    </label>
                    <Field
                      v-slot="{ field }"
                      name="state"
                      rules="required:state,true"
                      :validateOnInput="true"
                      v-model="users.state_id"
                    >
                      <Multiselect
                        v-bind="field"
                        rules="required:state,true"
                        ref="state_multiselect"
                        mode="single"
                        trackBy="name"
                        label="name"
                        valueProp="id"
                        placeholder="Select State"
                        class="form-control"
                        :close-on-select="true"
                        v-model="users.state_id"
                        :options="stateList"
                        searchable="true"
                      />
                    </Field>
                    <ErrorMessage name="state" class="validation-msg" />
                  </div>

                  <div class="col-sm-6 mb-3">
                    <label>
                      Pincode
                      <span class="text-danger">*</span></label
                    >
                    <Field
                      name="pincode"
                      id="pincode"
                      rules="required:pincode|pincode"
                      class="form-control"
                      v-model="users.pincode"
                      type="text"
                      placeholder="Pincode"
                    />
                    <ErrorMessage name="pincode" class="validation-msg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="col-12 text-center">
              <button
                type="button"
                @click="onCloseUpdateInfo()"
                class="btn btn-brand-01 wb-save-btn mx-2"
              >
                Cancel
              </button>
              <button
                type="submit"
                class="btn btn-brand-01 wb-save-btn"
                id="save-btn"
              >
                Submit
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
  <!-- Upload KYC Document Modal -->
  <div
    class="modal fade wb-modal-wrapper"
    id="uploadKycDocs"
    tabindex="-1"
    role="dialog"
    aria-labelledby="uploadKycDocs"
    aria-hidden="true"
    style="pointer-events: none"
  >
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <a
          class="close mr-3 mt-3 text-right"
          @click="closeModal()"
          aria-label="Close"
        >
          <img src="/static/img/close-icon.svg" width="20" />
        </a>
        <div class="modal-header justify-content-center">
          <h5 class="modal-title font-22">
            <span>Upload Document</span>
          </h5>
        </div>
        <Form
          @submit="onUploadkycDetails"
          class="columns column is-multiline is-12"
          ref="onUploadkycDetails"
        >
          <div class="modal-body form-style pb-0 px-lg-5">
            <div class="signin-form">
              <div class="col-lg-12 mb-4">
                <label
                  >Document Type
                  <span class="text-danger">*</span>
                </label>
                <Field
                  v-slot="{ field }"
                  name="document_type"
                  rules="required:document type,true"
                  :validateOnInput="true"
                  v-model="document.type"
                >
                  <Multiselect
                    v-bind="field"
                    ref="document_type_multiselect"
                    mode="single"
                    trackBy="type"
                    label="title"
                    valueProp="type"
                    @select="afterValueChange()"
                    placeholder="Select Document Type"
                    class="form-control"
                    v-model="document.type"
                    :options="documents"
                    :searchable="true"
                    :createOption="true"
                  />
                </Field>
                <ErrorMessage name="document_type" class="validation-msg" />
              </div>
              <div class="col-lg-12 mb-4" v-if="document.type === 'PAN'">
                <div class="form-group">
                  <label v-if="document.type === 'PAN'">
                    PAN Card No
                    <span class="text-danger">*</span>
                  </label>
                  <Field
                    name="pan_card_no"
                    v-model="document.pan_card_no"
                    class="form-control"
                    rules="required:PAN card no|PAN"
                    :validateOnInput="true"
                    type="text"
                    :placeholder="'Please enter PAN  card No'"
                    autocapitalize="false"
                  />
                  <ErrorMessage name="pan_card_no" class="validation-msg" />
                </div>
              </div>
              <div
                class="col-lg-12 mb-4"
                v-if="document.type == 'AADHAAR_CARD'"
              >
                <div class="form-group">
                  <label v-if="document.type == 'AADHAAR_CARD'">
                    Aadhaar Card No
                    <span class="text-danger">*</span>
                  </label>
                  <Field
                    name="aadhar_card_no"
                    v-model="document.aadhaar_card_no"
                    class="form-control"
                    rules="required:aadhaar card no|aadhaar"
                    :validateOnInput="true"
                    type="text"
                    :placeholder="'Please enter aadhaar card No'"
                    autocapitalize="false"
                  />
                  <ErrorMessage name="aadhar_card_no" class="validation-msg" />
                </div>
              </div>

              <div class="col-lg-12 mb-4">
                <div class="form-group">
                  <label
                    >Document
                    <span class="text-danger">*</span>
                  </label>
                  <div
                    class="wb-browse-file"
                    style="cursor: pointer"
                    @click="selectFile($event)"
                  >
                    <Field
                      name="upload_file"
                      v-model="fileName"
                      type="text"
                      placeholder="Browse File"
                      class="form-control"
                      :disabled="disabled == 1"
                    />
                    <Field
                      id="selectFile"
                      name="upload_file"
                      accept=".doc, .docx,.ppt, .pptx,.txt,.pdf,.jpeg,.jpg,.png,.webp"
                      style="display: none"
                      type="file"
                      placeholder="Browse File"
                      @change="onDocumentChange($event)"
                      class="form-control"
                    />
                    <a style="cursor: pointer" @click="selectFile($event)"
                      ><img src="/static/img/browse-icon.svg" alt=""
                    /></a>
                  </div>
                  <ErrorMessage name="upload_file" class="validation-msg" />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer border-0 justify-content-center mb-3">
            <button
              id="cancel-btn"
              @click="closeModal()"
              type="button"
              class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="btn btn-brand-01 wb-save-btn"
              id="kyc-document"
            >
              Save
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>
  <vue-easy-lightbox
    :visible="visibleRef"
    :imgs="showableImage"
    :index="indexRef"
    @hide="onHide"
  ></vue-easy-lightbox>
</template>
<script>
import VueEasyLightbox from "vue-easy-lightbox/dist/external-css/vue-easy-lightbox.esm.min.js";
import "vue-easy-lightbox/external-css/vue-easy-lightbox.css";
import { ref } from "vue";
import Pagination from "@/components/Pagination";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import ErrorComponent from "@/components/ErrorComponent";
import { Form, Field, ErrorMessage } from "vee-validate";
import $ from "jquery";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
export default {
  name: "CustomerDetail",
  components: {
    Pagination,
    ErrorComponent,
    Form,
    Field,
    ErrorMessage,
    Multiselect,
    VueEasyLightbox,
    VueDatePicker,
  },

  data() {
    return {
      loader: false,
      id: this.$route.params.id,
      permission: this.$route.params.access,
      currentPage: 1,
      totalPage: 0,
      itemsPerPage: 10,
      list: [],
      access: {
        can_read: 1,
        can_create: 1,
        can_update: 1,
        can_print: 1,
        can_delete: 1,
      },
      filterObj: {
        keyword: "",
        offset: 1,
        branch_ids: "",
        sort_by: "created_at",
        sort_order: "desc",
        row_per_page: 10,
      },
      detail: { info: { state: {} } },
      customer_detail_active_tab: "BASIC",
      documentList: [],
      document: {
        type: "AADHAAR_CARD",
        card_no: "",
      },
      documents: [
        {
          title: "Aadhaar Card",
          type: "AADHAAR_CARD",
        },
        {
          title: "PAN Card",
          type: "PAN",
        },
      ],
      users: {
        name: "",
        mobile_number: "",
        occupation: "",
        address: "",
        state_id: "",
        city: "",
        gender: "",
        date_of_birth: "",
        tier: "",
        loan_service: "",
        pincode: "",
        no_of_cycle_for_renew: 0,
      },
      user_type: "",
      stateList: [],
      userInfo: {},
      fileName: "",
      showableImage: "",
      visibleRef: ref(false),
      indexRef: ref(0),
      disabled: 1,
      occupations: [],
      occupation: "",
      kycDocumentType: "EDIT",
      kycDocummentUuid: "",
      max_approved_amount: 0,
      disabledDates: {
        to: new Date(),
      },
    };
  },
  computed: {
    branchList() {
      return this.$storeService.getters.getBranches;
    },
  },
  mounted() {
    if (this.id) {
      console.log("The Value is ", this.$helperService.getPastDate65YearsAgo().year);
      this.getCustomerInfo();
      this.onGetStateList();
      this.customer_detail_active_tab = localStorage.getItem(
        "customer_detail_active_tab"
      )
        ? localStorage.getItem("customer_detail_active_tab")
        : "BASIC";
      this.changeTab(this.customer_detail_active_tab);
      this.onGetOccupation();
    } else {
      this.$router.go(-1);
    }
    this.$storeService.commit("setTitle", "Customer Detail");
    this.user_type = localStorage.getItem("user_type");
  },
  methods: {
    
    afterValueChange() {
      this.document.card_no = "";
    },
    onClearForm(formRefName) {
      this.$refs[formRefName].resetForm();
    },
    onUpdateUserInfo() {
      this.getCustomerInfo();
      setTimeout(() => {
        window.$("#updateUserInfo").modal("show");
      }, 1000);
    },
    onCloseUpdateInfo() {
      window.$("#updateUserInfo").modal("hide");
      this.onClearForm("addressUpdate");
    },
    changeTab(tab) {
      this.customer_detail_active_tab = tab;
      localStorage.setItem("customer_detail_active_tab", tab);
      if (tab == "BASIC") {
        window.$("#tab-1").click();
      } else if (tab == "LOAN") {
        window.$("#tab-2").click();
        this.getList(1);
      } else {
        window.$("#tab-1").click();
        this.getList(1);
      }
    },
    onUplaodDocument() {
      window.$("#uploadKycDocs").modal("show");
    },
    closeModal() {
      window.$("#uploadKycDocs").modal("hide");
      this.document = {
        type: "AADHAAR_CARD",
        card_no: "",
      };
      this.fileName = "";
      this.kycDcoument = null;
      // this.onClearForm("onUploadkycDetails");
    },
    selectFile(e) {
      e.stopImmediatePropagation();
      window.$("#selectFile").click();
    },
    onDocumentChange(e) {
      let files = e.target.files;
      if (files.length > 0) {
        this.fileName = files[0].name;
        this.kycDcoument = files[0];
      } else {
        this.fileName = null;
        this.kycDcoument = null;
      }
    },
    reset() {
      this.filterObj = {
        keyword: "",
        offset: 1,
        sort_by: "created_at",
        sort_order: "desc",
        row_per_page: 10,
        status: "",
      };
      this.getList(1);
    },

    pageChange(page) {
      this.getList(page);
    },

    itemsPerPageChange() {
      var tab = this.customer_detail_active_tab;
      if (tab == "BASIC") {
        this.itemsPerPage = this.$refs.BASIC_PAGINATION.itemsPerPage;
      }
      if (tab == "LOAN") {
        this.itemsPerPage = this.$refs.LOAN_PAGINATION.itemsPerPage;
      }

      this.getList(1);
    },

    setPagination(page, count, listLength, paginationRef, errorCompRef) {
      if (page == 1) {
        if (count != null && count > 0) {
          if (this.$refs[paginationRef]) {
            this.$refs[paginationRef].setTotalCount(count);
          }
          if (this.$refs[errorCompRef]) {
            this.$refs[errorCompRef].setTitle(
              "Sorry, There is no record found.",
              false
            );
          }
        } else {
          if (this.$refs[paginationRef]) {
            this.$refs[paginationRef].setTotalCount(0);
          }
          if (this.$refs[errorCompRef]) {
            this.$refs[errorCompRef].setTitle(
              "Sorry, There is no record found.",
              true
            );
          }
        }
      }
      if (this.$refs[paginationRef]) {
        this.$refs[paginationRef].currentPage = this.currentPage;
      }
    },

    retry() {
      this.getList(1);
    },
    getList(page, sortBy, orderBy) {
      this.loader = true;
      // this.filterObj.status = this.customer_detail_active_tab;
      this.filterObj.offset = page - 1;
      this.currentPage = page;
      this.filterObj.row_per_page = this.itemsPerPage;

      if (sortBy) {
        this.filterObj.sort_by = sortBy;
      }
      if (orderBy) {
        this.filterObj.sort_order = orderBy;
      }
      this.filterObj.user_id = this.id;

      this.list = [];

      var method = "GET";

      this.$api
        .webRequest({
          _method: method,
          _action: `customer/${this.id}/loans`,
          _body: this.filterObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.access = res.access;
          var tab = this.customer_detail_active_tab;
          if (tab == "BASIC") {
            this.setPagination(
              page,
              res.count,
              "BASIC_PAGINATION",
              "BASIC_ERROR_COMPONENT"
            );
          } else if (tab == "LOAN") {
            this.setPagination(
              page,
              res.count,
              res.list.length,
              "LOAN_PAGINATION",
              "LOAN_ERROR_COMPONENT"
            );
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setTitle();
          }
        });
    },
    sorting(e, sortBy) {
      var orderBy = "ASC";

      var className = "sorting_asc";
      var classes = e.target.className;
      if (classes.includes("sorting_asc")) {
        className = "sorting_desc";
      }

      $(".sorting_asc").removeClass("sorting_asc");
      $(".sorting_desc").removeClass("sorting_desc");

      if (className == "sorting_asc") {
        $(e.target).addClass("sorting_asc").removeClass("sorting_desc");
        orderBy = "ASC";
      } else if (className == "sorting_desc") {
        $(e.target).addClass("sorting_desc").removeClass("sorting_asc");
        orderBy = "DESC";
      }
      this.getList(1, sortBy, orderBy);
    },
    view(item, can_update) {
      if (item.client_status == "PENDING") {
        this.$router.push(
          "/view-loan-application/" + item.uuid + "/" + can_update
        );
      }
      if (
        item.client_status == "REJECTED" ||
        item.client_status == "CANCELLED"
      ) {
        this.$router.push(
          "/view-loan-application/" + item.uuid + "/" + can_update
        );
      }
      if (item.client_status == "ONGOING") {
        this.$router.push("/view-disbursed/" + item.uuid);
      }
    },
    getCustomerInfo() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "customer/" + this.id,
        })
        .then((res) => {
          this.max_approved_amount = res?.info?.max_approved_amount
            ? res?.info?.max_approved_amount
            : 0;
          this.access = res?.access;
          this.detail = res.info;
          this.users.name = res?.info?.name;
          this.users.mobile_number = res?.info?.mobile_number;
          this.users.address = res?.info?.info?.address;
          this.users.occupation = res?.info?.info?.occupation;
          this.occupation = res?.info?.info?.occupation?.value;
          this.users.tier = res?.info?.level;
          this.users.loan_service = res?.info?.loan_service;
          this.users.city = res?.info?.info?.city;
          this.users.date_of_birth = res?.info?.info?.date_of_birth;
          this.users.gender = res?.info?.info?.gender;
          this.users.state_id = res?.info?.info?.state_id;
          this.users.branch_id = res?.info?.branch?.uuid;
          if (res?.no_of_cycle_for_renew != 0) {
            this.users.no_of_cycle_for_renew = res?.info?.no_of_cycle_for_renew;
          }
          this.users.pincode = res?.info?.info?.pincode;
          this.userInfo.level = res.info.level;
          this.userInfo.loan_service = res.info.loan_service;

          this.documentList = [
            {
              title: "PAN Card",
              image_url: res?.info?.info?.pan_card_img,
              doc_no: res?.info?.info?.pan_card_no,
              updated_at: res?.info?.info?.updated_at,
              type: "PAN",
            },
            {
              title: "Aadhar Card",
              type: "AADHAAR_CARD",
              image_url: res?.info?.info?.aadhaar_card_img,
              doc_no: res?.info?.info?.aadhaar_card_no,
              updated_at: res?.info?.info?.updated_at,
            },
          ];
          this.documentList;
          console.log("new array:", this.documentList);
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    download(url, filename) {
      const link = document.createElement("a");
      link.target = "_blank";
      link.href = url;
      link.download = filename;
      link.click();
    },
    onFinalUpdateInfo() {
      this.users.date_of_birth = moment(
        String(this.users.date_of_birth)
      ).format("Y-MM-DD");

      this.users.occupation = this.occupation;
      this.$api
        .webRequest({
          _method: "PUT",
          _action: "customer/" + this.id,
          _buttonId: "save-btn",
          _body: this.users,
        })
        .then((res) => {
          this.onCloseUpdateInfo();
          this.$toast.success(res.message, { position: "top-right" });
          this.getCustomerInfo();
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    onGetStateList() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "select/states",
        })
        .then((res) => {
          this.stateList = res.list;
        })
        .catch(() => {});
    },
    onGetOccupation() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "select/occupations",
        })
        .then((res) => {
          this.occupations = res.list;
        })
        .catch(() => {});
    },
    openImage(image, index) {
      this.showableImage = image;
      this.indexRef = index;
      this.visibleRef = true;
    },
    onHide() {
      this.visibleRef = false;
    },
    onUploadkycDetails() {
      var obj = {
        type: this.document.type,
      };

      if (this.document.type == "AADHAAR_CARD") {
        obj.aadhaar_card_no = this.document.aadhaar_card_no;
      }

      if (this.document.type == "PAN") {
        obj.pan_card_no = this.document.pan_card_no;
      }

      console.log(
        "data coming form upload document",
        this.kycDcoument,
        this.document
      );
      this.$api
        .webRequest({
          _method: "PUT",
          _action: "customer/" + this.id + "/kyc",
          _buttonId: "kyc-document",
          _body: obj,
        })
        .then((res) => {
          this.$toast.success(res.message, { position: "top-right" });

          if (typeof this.kycDcoument == "object") {
            this.onUploadKyCFiles(this.kycDcoument, this.document.type);
          } else {
            this.closeModal();
            this.getCustomerInfo();
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    editKycDocument(item) {
      this.kycDocummentUuid = item?.uuid;
      this.document = {
        type: item?.type,
      };
      if (item?.type == "AADHAAR_CARD") {
        this.document.aadhaar_card_no = item?.doc_no;
      }

      if (item?.type == "PAN") {
        this.document.pan_card_no = item?.doc_no;
      }

      this.fileName = item?.image_url;
      console.log("edit kyc details : ", item);
      window.$("#uploadKycDocs").modal("show");
    },
    onUploadKyCFiles(file, type) {
      this.$api
        .uploadImageAPI({
          _action: "customer/" + this.id + "/kyc/documents",
          _key: "file",
          _buttonId: "kyc-document",
          _file: file,
          _body: { type: type },
        })
        .then((res) => {
          this.$toast.success(res.message, { position: "top-right" });
          this.closeModal();
          this.getCustomerInfo();
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },

    downloadPdf(url, filename) {
      console.log(url, filename);
      var anchorElement = document.createElement("a");
      anchorElement.href = url;
      anchorElement.download = filename;
      anchorElement.target = "_blank";
      document.body.appendChild(anchorElement);
      console.log(anchorElement);
      anchorElement.click();
      document.body.removeChild(anchorElement);
    },
    commonFunctionForRedirect(item) {
      if (item?.status == "PENDING") {
        this.$router.push("/view-loan-application/" + item?.uuid)
      }
      if (item?.status == "PENDING_FOR_VERIFICATION") {
        this.$router.push("/view-loan-application/" + item?.uuid)
      }
      if (item?.status == "DOCUMENT_VERIFIED") {
        this.$router.push("/view-verified-loan-application/" + item?.uuid)
      }
      if (item?.status == "PENDING_FOR_FINAL_APPROVAL") {
        this.$router.push("/view-verified-loan-application/" + item?.uuid)
      }
      if (item?.status == "FINAL_APPROVAL") {
        this.$router.push("/view-loan-application/" + item?.uuid)
      }
      if (item?.status == "CANCELLED") {
        this.$router.push("/view-loan-application/" + item?.uuid)
      }
      if (item?.status == "DISBURSED") {
        this.$router.push("/view-disbursed/" + item?.uuid)
      }
      if (item?.status == "DOCUMENT_REJECTED" || item?.status == "APPLICATION_REJECTED" || item?.status == "REJECTED") {
        this.$router.push("/view-loan-application/" + item?.uuid)
      }
    },
  },
};
</script>
