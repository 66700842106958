<template>
  <div>
    <div class="content-body">
      <div class="component-section no-code">
        <div class="row d-flex justify-content-between">
          <div class="col-md-4 pr-2">
            <h2 class="content-title">
              <a @click="onBack()" style="cursor: pointer"><i class="fas fa-angle-left mr-2"></i><span>{{
                $helperService.getTitleCase(type) + " - " + name }}</span>
              </a>
            </h2>
          </div>
        </div>
        <div class="container-fluid px-0">
          <div class="row">
            <div class="col-lg-12">
              <ul id="myTab" role="tablist" class="nav nav-tabs worker_tabs">
                <li class="nav-item">
                  <a id="tab-1" @click="changeTab('SUMMARY')" data-toggle="tab" href="#tab_1" role="tab"
                    aria-controls="tab_1" aria-selected="true" class="nav-link active">Summary</a>
                </li>
                <li class="nav-item">
                  <a id="tab-2" @click="changeTab('EXPENSE')" data-toggle="tab" href="#tab_2" role="tab"
                    aria-controls="tab_2" aria-selected="false" class="nav-link">Statement</a>
                </li>
                <!-- <li class="nav-item">
                                    <a id="tab-3" @click="changeTab('SALARY')" data-toggle="tab" href="#tab_3" role="tab"
                                        aria-controls="tab_3" aria-selected="false" class="nav-link">Salary</a>
                                </li> -->
              </ul>
              <div id="myTabContent" class="tab-content">
                <div id="tab_1" role="tabpanel" aria-labelledby="tab-1" class="tab-pane fade active show">
                  <div class="row">
                    <div class="col-lg-8 pt-3 mx-auto">
                      <div class="card rounded-2" style="border-radius: 10px">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-lg-4">
                              <h5 class="label-text">Employee Name</h5>
                            </div>
                            <div class="col-lg-8">
                              <h5 class="text-bolder">: {{ userSummary?.name }}</h5>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-4">
                              <h5 class="label-text">Branch</h5>
                            </div>
                            <div class="col-lg-8">
                              <template v-for="(branch, i) in userSummary?.branches" :key="branch.id">
                                <h5 v-if="i + 1 != userSummary?.branches.length">
                                  : {{ branch.name + ", " }}
                                </h5>
                                <h5 v-else>: {{ branch.name }}</h5>
                              </template>
                              <!-- <h5 class="text-bolder"> : {{ userSummary?.city }}</h5> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-8 pt-3 mx-auto">
                      <div class="card rounded-2" style="border-radius: 10px">
                        <div class="card-body">
                          <!-- <div class="col-lg-3">
                                                            <h5 class="label-text">Total Due Amount</h5>
                                                        </div>
                                                        <div class="col-lg-9">
                                                            <h5 class="text-bolder"> : <span> ₹ {{ userSummary?.total_due }}
                                                                </span>
                                                            </h5>
                                                        </div> -->
                          <!-- <div class="row">
                            <div class="col-lg-4">
                              <h5 class="label-text">Total Advance Payment</h5>
                            </div>
                            <div class="col-lg-4">
                              <h5 class="text-bolder">
                                :
                                <span>
                                  {{
                                    $helperService.getFormattedCurrency(
                                      userSummary?.total_advance_payment
                                    )
                                  }}
                                </span>
                              </h5>
                            </div>
                            <div
                              class="col-lg-4"
                              v-if="userSummary?.total_advance_payment > 0 ? true : false"
                            >
                              <a
                                class="btn green-box"
                                href="javascript:void(0)"
                                style="cursor: pointer !important; padding: 4px"
                                v-if="permission == 1"
                                @click="onMoneyInOut('MONEY_IN', 'TOTAL_ADVANCE_PAYMENT')"
                                ><span>Payment Receive</span>
                              </a>
                            </div>
                          </div>
                          <div
                            class="row"
                            v-if="userSummary?.user_type == 'COLLECTION_MANAGER'"
                          >
                            <div class="col-lg-4">
                              <h5 class="label-text">Total Incentive</h5>
                            </div>
                            <div class="col-lg-4">
                              <h5 class="text-bolder">
                                :
                                <span>
                                  {{
                                    $helperService.getFormattedCurrency(
                                      userSummary?.total_due_incentive
                                    )
                                  }}
                                </span>
                              </h5>
                            </div>
                            <div
                              class="col-lg-4"
                              v-if="userSummary?.total_due_incentive > 0"
                            >
                              <a
                                href="javascript:void(0)"
                                style="cursor: pointer !important; padding: 4px"
                                class="btn red-box"
                                v-if="permission == 1"
                                @click="onMoneyInOut('MONEY_OUT', 'TOTAL_INCENTIVE')"
                                ><span>Payout</span></a
                              >
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-4">
                              <h5 class="label-text">Total Expenses</h5>
                            </div>
                            <div class="col-lg-4">
                              <h5 class="text-bolder">
                                :
                                <span>
                                  {{
                                    $helperService.getFormattedCurrency(
                                      userSummary?.total_due_expense
                                    )
                                  }}
                                </span>
                              </h5>
                            </div>
                            <div
                              class="col-lg-4"
                              v-if="userSummary?.total_due_expense > 0"
                            >
                              <a
                                href="javascript:void(0)"
                                style="cursor: pointer !important; padding: 4px"
                                v-if="permission == 1"
                                class="btn red-box"
                                @click="onMoneyInOut('MONEY_OUT', 'TOTAL_DUE_EXPENSE')"
                                ><span>Payout</span></a
                              >
                            </div>
                          </div> -->

                          <div class="row">
                            <div class="col-lg-4">
                              <h5 class="label-text">Total Balance</h5>
                            </div>
                            <div class="col-lg-4">
                              <h5 class="text-bolder">
                                :
                                <span>
                                  {{
                                    $helperService.getFormattedCurrency(
                                      userSummary?.total_balance
                                    )
                                  }}
                                </span>
                              </h5>
                            </div>
                            <!-- <div
                              class="col-lg-4"
                              v-if="userSummary?.total_due_expense > 0"
                            >
                              <a
                                href="javascript:void(0)"
                                style="cursor: pointer !important; padding: 4px"
                                v-if="permission == 1"
                                class="btn red-box"
                                @click="onMoneyInOut('MONEY_OUT', 'TOTAL_DUE_EXPENSE')"
                                ><span>Payout</span></a
                              >
                            </div> -->
                          </div>

                          <div class="row">
                            <div class="col-lg-8 mx-auto pt-5 pb-3">
                              <div class="d-flex flex-row justify-content-center">
                                <a class="btn mx-1 green-box" href="javascript:void(0)" v-if="permission == 1"
                                  style="cursor: pointer !important" @click="onMoneyInOut('MONEY_IN')"><span>Payment
                                    Receive</span></a>
                                <a href="javascript:void(0)" v-if="permission == 1" style="cursor: pointer !important"
                                  class="btn mx-1 red-box" @click="onMoneyInOut('MONEY_OUT')"><span>Payout</span></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="tab_2" role="tabpanel" aria-labelledby="tab-2" class="tab-pane fade">
                  <div class="worker_serch_warp">
                    <div class="row">
                      <div class="col-lg-3 mb-2">
                        <div class="search-form position-relative">
                          <input type="text" class="form-control" v-model="filterObj.keyword" v-on:blur="getList(1)"
                            placeholder="Search" />
                          <div class="input-group-prepend position-absolute">
                            <button class="" type="button" id="button-addon1">
                              <img src="/static/img/search.svg" width="15" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 mb-2">
                        <VueDatePicker placeholder="Select Payment Date Range" :format="'dd/MM/yyyy'"
                          style="height: 46px !important" v-model="payment_date" range :type="boolean" :default="true"
                          :enable-time-picker="false">
                        </VueDatePicker>
                      </div>
                      <div class="col-lg-3 mb-2">
                        <VueDatePicker placeholder="Select Added Date Range" :format="'dd/MM/yyyy'"
                          style="height: 46px !important" v-model="added_date" range :type="boolean" :default="true"
                          :enable-time-picker="false">
                        </VueDatePicker>
                      </div>
                      <div class="col-lg-3 mb-2">
                        <a @click="getList(1)" class="btn btn-brand-01 mr-2">Search </a>
                        <a @click="reset()" class="btn btn-brand-03 mr-2">Reset </a>
                      </div>
                    </div>
                  </div>

                  <div class="card rounded-5">
                    <div class="card-body p-3 p-lg-3">

                      <div class="row">
                        <div class="col-lg-3 mb-2 ">
                          <span class="font-weight-bold" style="font-size: 16px !important; color: black !important">#
                            Total Debit :
                            <h4>{{ $helperService.getFormattedCurrency(total_debit) }}</h4>
                          </span>
                        </div>
                        <div class="col-lg-3 mb-2 ">
                          <span class="font-weight-bold" style="font-size: 16px !important; color: black !important">#
                            Total Credit :
                            <h4>{{ $helperService.getFormattedCurrency(total_credit) }}</h4>
                          </span>
                        </div>

                        <div class="col-lg-6 mb-2 text-right">
                          <button v-if="access.can_print == 1" @click="onExportReport()" class="btn btn-brand-01 mr-2"
                            id="cibil_report">
                            <i class="fa fa-download" aria-hidden="true"></i>
                            Export
                          </button>
                        </div>
                      </div>


                      <div class="table-responsive">
                        <table class="table table-primary mg-b-0">
                          <thead>
                            <tr>
                              <th scope="col" width="10%" class="sorting"
                                @click="sorting($event, 'employee_ledgers.payment_date')">
                                Payment Date
                              </th>
                              <th scope="col" width="10%" class="sorting"
                                @click="sorting($event, 'employee_ledgers.type')">
                                Type
                              </th>
                              <th scope="col" width="30%">Particulars</th>
                              <th scope="col" width="15%" class="sorting" @click="sorting($event, 'admin_users.name')">
                                Added By
                              </th>
                              <th scope="col" width="15%" class="sorting"
                                @click="sorting($event, 'employee_ledgers.created_at')">
                                Added Date
                              </th>
                              <th scope="col" width="20%" class="text-center sorting"
                                @click="sorting($event, 'employee_ledgers.amount')">
                                Amount
                              </th>
                              <th scope="col" class="">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="loader">
                              <td colspan="6" class="text-center">
                                <div class="spinner-border spinner-color" role="status"></div>
                              </td>
                            </tr>
                            <tr v-else v-for="item in list" :key="item.id">
                              <td v-if="item.payment_date">
                                {{
                                  $helperService.getFormattedDateOnly(item.payment_date)
                                }}
                              </td>
                              <td v-else>-</td>
                              <td>{{ $helperService.getTitleCase(item.type) }}</td>
                              <td>{{ item?.narration }}</td>
                              <td>{{ item?.added_by?.name }}</td>
                              <td>
                                {{ $helperService.getFormattedDate(item.created_at) }}
                              </td>
                              <td v-if="item.trans_type == 'DEBIT'" class=" red-text text-right">
                                {{ $helperService.getFormattedCurrency(item?.amount) }}
                              </td>
                              <td v-if="item.trans_type == 'CREDIT'" class="green-text text-right">
                                {{ $helperService.getFormattedCurrency(item?.amount) }}
                              </td>
                              <td>
                                <a title="View" v-if="hasFullAccess" class="mx-2" style="cursor: pointer"
                                  @click="onDeleteEvent(item)"><img src="/static/img/delete-icon.svg" alt="" /></a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <ErrorComponent @retry="changeTab(active_tab)" ref="EXPENSE_ERROR_COMPONENT" />
                      </div>
                      <div class="row">
                        <div class="col-lg-12 mt-5 text-center">
                          <div class="page-bottom-pagination text-right">
                            <Pagination @page-change="pageChange" @items-per-page-change="itemsPerPageChange"
                              ref="EXPENSE_PAGINATION" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div id="tab_3" role="tabpanel" aria-labelledby="tab-3" class="tab-pane fade">
                                    <div class="card rounded-5">
                                        <div class="card-body p-3 p-lg-3">
                                            <div class="table-responsive">
                                                <table class="table table-primary mg-b-0">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" class="sorting"
                                                                @click="sorting($event, 'payment_date')">
                                                                Date
                                                            </th>
                                                            <th scope="col" class="sorting"
                                                                @click="sorting($event, 'name')">
                                                                Particulars
                                                            </th>

                                                            <th scope="col" class="sorting"
                                                                @click="sorting($event, 'asset_code')">
                                                                Added By
                                                            </th>
                                                            <th scope="col" class="sorting"
                                                                @click="sorting($event, 'amount')">
                                                                Amount
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-if="loader">
                                                            <td colspan="5" class="text-center">
                                                                <div class="spinner-border spinner-color" role="status">
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr v-else v-for="item in list" :key="item.id">
                                                            <td>{{ $helperService.getFormattedDate(item.created_at) }}</td>
                                                            <td>{{ item?.narration }}</td>
                                                            <td>{{ item?.added_by?.name }}</td>
                                                            <td v-if="item.trans_type == 'DEBIT'" class="red-text"> ₹ {{
                                                                item?.amount }}</td>
                                                            <td v-if="item.trans_type == 'CREDIT'" class="green-text"> ₹ {{
                                                                item?.amount }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <ErrorComponent @retry="changeTab(active_tab)"
                                                    ref="SALARY_ERROR_COMPONENT" />
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12 mt-5 text-center">
                                                    <div class="page-bottom-pagination text-right">
                                                        <Pagination @page-change="pageChange"
                                                            @items-per-page-change="itemsPerPageChange"
                                                            ref="SALARY_PAGINATION" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="viewModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" v-if="moneyType == 'MONEY_OUT'">
              Payout - {{ userSummary.name }}
            </h5>
            <h5 class="modal-title" v-if="moneyType == 'MONEY_IN'">
              Payment Receive - {{ userSummary.name }}
            </h5>
            <button type="button" class="close" @click="oncloseMoneyInOut()" aria-label="Close">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <Form @submit="onUpdateMoneyInOut" class="columns column" data-vv-scope="ProfileSave" ref="moneyInOutUpdate">
            <div class="modal-body">
              <div class="row">
                <div class="col-lg-12">
                  <div class="row">
                    <div class="col-sm-6 mb-3">
                      <label>
                        Branch
                        <span class="text-danger">*</span>
                      </label>
                      <Multiselect ref="branch_id_multiselect" name="branch_id" mode="single" trackBy="name"
                        label="name" valueProp="branch_id" placeholder="Select Branch" class="form-control"
                        v-model="moneyObj.branch_id" :options="branchList" @close="SearchApiCall()"
                        @change="canDeSelect()" searchable="true" />
                    </div>
                    <div class="col-sm-6 mb-3" v-if="moneyType == 'MONEY_OUT'">
                      <label>
                        Type
                        <span class="text-danger">*</span>
                      </label>
                      <Field v-slot="{ field }" name="moneyOutType" v-model="moneyObj.type">
                        <Multiselect v-bind="field" rules="required:type" ref="multiselect" mode="single"
                          trackBy="title" label="title" valueProp="value" placeholder="Select Type" class="form-control"
                          v-model="moneyObj.type" :options="moneyOutTypes" @close="SearchApiCall()"
                          @select="canDeSelect()" :searchable="false" />
                      </Field>
                      <ErrorMessage name="moneyOutType" class="validation-msg" />
                    </div>
                    <div class="col-sm-6 mb-3" v-if="moneyType == 'MONEY_IN'">
                      <label>
                        Type
                        <span class="text-danger">*</span>
                      </label>
                      <Field v-slot="{ field }" name="moneyInType" v-model="moneyObj.type">
                        <Multiselect v-bind="field" rules="required:type" ref="multiselect" mode="single"
                          trackBy="title" label="title" valueProp="value" placeholder="Select Type" class="form-control"
                          v-model="moneyObj.type" :options="moneyInTypes" :searchable="false" />
                      </Field>
                      <ErrorMessage name="moneyInType" class="validation-msg" />
                    </div>
                    <div class="col-sm-6 mb-3">
                      <label style="width: 100%">
                        Amount
                        <span class="text-danger">*</span>
                        <span v-if="isShow" style="font-size: 10px; color: red; float: right">Total Due Amount :
                          {{ $helperService.getFormattedCurrency(due_values) }}</span>
                      </label>
                      <input name="amount" id="amount" rules="required:amount|numeric" step="1" class="form-control"
                        v-model="moneyObj.amount" type="number" v-bind:placeholder="'Amount'" />
                      <ErrorMessage name="amount" class="validation-msg" />
                    </div>
                    <div class="col-sm-6 mb-3" v-if="moneyType == 'MONEY_OUT'">
                      <label style="width: 100%"> TDS Amount </label>
                      <input name="tds_amount" id="tds_amount" rules="numeric" step="1" class="form-control"
                        v-model="moneyObj.tds_amount" type="number" v-bind:placeholder="'TDS Amount'" />
                      <ErrorMessage name="tds_amount" class="validation-msg" />
                    </div>
                    <div class="col-sm-6 mb-3" v-if="moneyType == 'MONEY_OUT'">
                      <label style="width: 100%"> GST Amount </label>
                      <input name="gst_amount" id="gst_amount" rules="required:amount|numeric" step="1"
                        class="form-control" v-model="moneyObj.gst_amount" type="number"
                        v-bind:placeholder="'GST Amount'" />
                      <ErrorMessage name="gst_amount" class="validation-msg" />
                    </div>
                    <div class="col-sm-12 mb-3">
                      <label>Payment Date
                        <span class="text-danger">*</span>
                      </label>
                      <Field name="payment_date" class="form-control" v-slot="{ date }" rules="required:payment date"
                        id="payment_date" v-model="moneyObj.payment_date">
                        <VueDatePicker v-bind="date" placeholder="Payment Date" style="height: 46px; !important"
                          v-model="moneyObj.payment_date" :type="boolean" :format="'dd/MM/yyyy'" :default="true"
                          :enable-time-picker="false">
                        </VueDatePicker>
                      </Field>
                      <ErrorMessage name="payment_date" class="validation-msg" />
                    </div>
                    <div class="col-sm-12 mb-3">
                      <label>
                        Description
                        <span class="text-danger">*</span></label>
                      <Field v-slot="{ field }" name="description" v-model="moneyObj.narration"
                        rules="required:description" :validateOnInput="true" type="text" autocapitalize="false">
                        <textarea v-bind="field" placeholder="Description" cols="5" rows="5" class="form-control"
                          v-model="moneyObj.narration"></textarea>
                      </Field>
                      <ErrorMessage name="description" class="validation-msg" />
                    </div>
                  </div>
                  <div class="col-12 text-center">
                    <button type="submit" class="btn btn-info wb-save-btn py-2" id="save-btn">
                      Submit
                    </button>
                    <!-- <button type="button" class="btn btn-outline-secondary">Cancel</button> -->
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
  <ConfirmationModal @remove="onDeleteUser" ref="deleteRole" />
</template>
<script>
import Pagination from "@/components/Pagination"
import ErrorComponent from "@/components/ErrorComponent"
import ConfirmationModal from "@/components/ConfirmationModal.vue";
import Multiselect from "@vueform/multiselect"
import "@vueform/multiselect/themes/default.css"
import { Form, Field, ErrorMessage } from "vee-validate"
import $ from "jquery"
import VueDatePicker from "@vuepic/vue-datepicker"
import "@vuepic/vue-datepicker/dist/main.css"
import moment from "moment"

export default {
  name: "EmployeeAccountDetails",
  components: {
    Pagination,
    ErrorComponent,
    Form,
    Field,
    ErrorMessage,
    Multiselect,
    VueDatePicker,
    ConfirmationModal,
  },
  computed: {
    hasFullAccess() {
      return this.$storeService.getters.getHasFullAccess;
    }
  },

  data() {
    return {
      type: "EMPLOYEE_EXPENSE",
      id: this.$route.params.uuid,
      name: "",
      permission: this.$route.params.access,
      currentPage: 1,
      totalPage: 0,
      itemsPerPage: 10,
      list: [],
      total_credit: 0,
      total_debit: 0,
      payment_date: "",
      added_date: "",
      access: {
        can_read: 1,
        can_create: 1,
        can_update: 1,
        can_print: 1,
        can_delete: 1,
      },
      filterObj: {
        keyword: "",
        offset: 1,
        payment_start_date: "",
        payment_end_date: "",
        created_start_date: "",
        created_end_date: "",
        sort_by: "employee_ledgers.created_at",
        sort_order: "desc",
        row_per_page: 10,
        level: "",
        status: "",
      },
      isShow: false,
      moneyObj: {
        branch_id: "",
        type: "",
        amount: "",
        tds_amount: "0",
        gst_amount: "0",
        narration: "",
        payment_date: "",
      },
      delete_id: "",
      statusOption: [
        {
          title: "Active",
          value: "ACTIVE",
        },
        {
          title: "Inactive",
          value: "INACTIVE",
        },
      ],
      moneyOutTypes: [
        {
          title: "Advance",
          value: "ADVANCE_PAYMENT",
        },
        {
          title: "Expense",
          value: "EXPENSE",
        },
        {
          title: "Performance Bonus",
          value: "INCENTIVE",
        },
        {
          title: "Other",
          value: "OTHER",
        },
      ],
      moneyInTypes: [
        {
          title: "Advance Return",
          value: "RETURN",
        },
        {
          title: "Other",
          value: "OTHER",
        },
      ],
      branchList: [],
      active_tab: "SUMMARY",
      loader: false,
      userSummary: null,
      moneyType: "",
      due_values: "",
      employee_id: "",
    }
  },
  // computed: {
  //     branchList() {
  //         return this.$storeService.getters.getBranches;
  //     },
  //     hasFullAccess() {
  //         return this.$storeService.getters.getHasFullAccess;
  //     }
  // },
  mounted() {
    this.active_tab = localStorage.getItem("active_tab")
      ? localStorage.getItem("active_tab")
      : "SUMMARY"
    this.$storeService.commit("setTitle", "Employee Account")
    this.changeTab(this.active_tab)
  },
  methods: {
    onDeleteEvent(item) {
      this.employee_id = item?.employee?.uuid;
      this.delete_id = item?.uuid;
      this.$refs.deleteRole.showModal("Confirmation", "Are you sure you want to delete?");
    },
    onBack() {
      localStorage.removeItem("active_tab");
      this.$router.go(-1);
    },
    onClearForm(formRefName) {
      this.$refs[formRefName].resetForm()
    },
    canDeSelect() {
      this.isShow = false
      this.due_values = ""
    },
    SearchApiCall() {
      setTimeout(() => {
        if (!this.moneyObj.type || !this.moneyObj.branch_id) {
          console.log(this.moneyObj.type + " >> " + this.moneyObj.branch_id)
          return false
        }
        if (
          // this.moneyObj.type == "SALARY" ||
          this.moneyObj.type == "EXPENSE" ||
          this.moneyObj.type == "INCENTIVE"
        ) {
          this.$api
            .webRequest({
              _method: "GET",
              _action: "employee/" + this.id + "/dues",
              _body: { branch_id: this.moneyObj.branch_id, type: this.moneyObj.type },
            })
            .then((res) => {
              this.due_values = res?.due_amount
              this.isShow = true
            })
            .catch(() => { })
        }
      }, 500)
    },
    onUpdateMoneyInOut() {
      if (this.moneyType == "MONEY_IN") {
        this.moneyObj.trans_type = "CREDIT"
        this.moneyObj.tds_amount = "0"
        this.moneyObj.gst_amount = "0"
      }
      if (this.moneyType == "MONEY_OUT") {
        this.moneyObj.trans_type = "DEBIT"
      }

      this.moneyObj.payment_date = moment(String(this.moneyObj.payment_date)).format(
        "Y-MM-DD"
      )

      this.$api
        .webRequest({
          _method: "POST",
          _action: "employee/" + this.id + "/ledger",
          _buttonId: "save-btn",
          _body: this.moneyObj,
        })
        .then((res) => {
          this.onClearForm("moneyInOutUpdate")
          this.oncloseMoneyInOut()
          this.$toast.success(res.message, { position: "top-right" })
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" })
        })
    },
    onMoneyInOut(type, from) {
      this.moneyType = null
      this.moneyObj = {
        branch_id: "",
        type: "",
        amount: "",
        tds_amount: "0",
        gst_amount: "0",
        narration: "",
        payment_date: "",
      }
      this.moneyObj.branch_id = ""

      if (from == "TOTAL_ADVANCE_PAYMENT") {
        this.moneyObj.type = "RETURN"
      }
      if (from == "TOTAL_INCENTIVE") {
        this.moneyObj.type = "INCENTIVE"
      }
      if (from == "TOTAL_DUE_EXPENSE") {
        this.moneyObj.type = "EXPENSE"
      }
      window.$("#viewModal").modal("show")
      this.moneyType = type
    },
    oncloseMoneyInOut() {
      window.$("#viewModal").modal("hide")
      this.moneyType = null
      this.moneyObj = {
        branch_id: "",
        type: "",
        amount: "",
        tds_amount: "0",
        narration: "",
        payment_date: "",
      }
      this.moneyObj.branch_id = ""
      this.isShow = false
      this.due_values = 0
      this.changeTab(this.active_tab);
    },
    changeTab(tab) {
      this.active_tab = tab
      localStorage.setItem("active_tab", tab)
      if (tab == "SUMMARY") {
        this.getUserSummary()
      } else if (tab == "EXPENSE") {
        window.$("#tab-2").click()
        this.getList(1)
      } else if (tab == "SALARY") {
        window.$("#tab-3").click()
        this.getList(1)
      } else {
        window.$("#tab-1").click()
        this.getList(1)
      }
    },
    reset() {
      this.filterObj = {
        keyword: "",
        offset: 1,
        sort_by: "employee_ledgers.created_at",
        sort_order: "desc",
        payment_start_date: "",
        payment_end_date: "",
        created_start_date: "",
        created_end_date: "",
        row_per_page: 10,
        status: "",
      }
      this.payment_date = null
      this.added_date = null
      this.getList(1)
    },
    view(item) {
      this.$router.push("/view-employee-expense/" + item.uuid)
    },
    addOfficeExpense() {
      localStorage.setItem("expense_status", "EMPLOYEE")
      this.$router.push("/add-expense")
    },
    pageChange(page) {
      this.getList(page)
    },
    itemsPerPageChange() {
      var tab = this.active_tab

      if (tab == "EXPENSE") {
        this.itemsPerPage = this.$refs.EXPENSE_PAGINATION.itemsPerPage
      }
      if (tab == "SALARY") {
        this.itemsPerPage = this.$refs.SALARY_PAGINATION.itemsPerPage
      }

      this.getList(1)
    },
    setPagination(page, count, listLength, paginationRef, errorCompRef) {
      if (page == 1) {
        if (count != null && count > 0) {
          if (this.$refs[paginationRef]) {
            this.$refs[paginationRef].setTotalCount(count)
          }
          if (this.$refs[errorCompRef]) {
            this.$refs[errorCompRef].setTitle("Sorry, There is no record found.", false)
          }
        } else {
          if (this.$refs[paginationRef]) {
            this.$refs[paginationRef].setTotalCount(0)
          }
          if (this.$refs[errorCompRef]) {
            this.$refs[errorCompRef].setTitle("Sorry, There is no record found.", true)
          }
        }
      }
      if (this.$refs[paginationRef]) {
        this.$refs[paginationRef].currentPage = this.currentPage
      }
      // var checkValue = false;
      // if (listLength === 0) {
      //     checkValue = true;
      // }
      // if (checkValue) {
      //     if (this.$refs[errorCompRef]) {
      //         this.$refs[errorCompRef].setTitle("Sorry, There is no record found.");
      //     }
      // }
    },
    retry() {
      this.getList(1)
    },
    getList(page, sortBy, orderBy) {
      this.filterObj.type = this.active_tab
      this.filterObj.offset = page - 1
      this.filterObj.trans_type = "ALL"
      this.currentPage = page
      this.filterObj.row_per_page = this.itemsPerPage

      if (sortBy) {
        this.filterObj.sort_by = sortBy
      }
      if (orderBy) {
        this.filterObj.sort_order = orderBy
      }

      if (this.payment_date) {
        this.filterObj.payment_start_date = moment(String(this.payment_date[0])).format(
          "Y-MM-DD"
        )
        this.filterObj.payment_end_date = moment(String(this.payment_date[1])).format(
          "Y-MM-DD"
        )
      } else {
        this.filterObj.payment_start_date = ""
        this.filterObj.payment_end_date = ""
      }

      if (this.added_date) {
        this.filterObj.created_start_date = moment(String(this.added_date[0])).format(
          "Y-MM-DD"
        )
        this.filterObj.created_end_date = moment(String(this.added_date[1])).format(
          "Y-MM-DD"
        )
      } else {
        this.filterObj.created_start_date = ""
        this.filterObj.created_end_date = ""
      }

      this.list = []

      this.loader = true

      var method = "GET"
      this.$api
        .webRequest({
          _method: method,
          _action: "employee/" + this.id + "/ledgers",
          _body: this.filterObj,
        })
        .then((res) => {
          this.loader = false
          this.list = res.list
          this.total_credit = res.total_credit
          this.total_debit = res.total_debit
          this.access = res.access
          var tab = this.active_tab
          if (tab == "EXPENSE") {
            this.setPagination(
              page,
              res.count,
              res.list.length,
              "EXPENSE_PAGINATION",
              "EXPENSE_ERROR_COMPONENT"
            )
          } else if (tab == "SALARY") {
            this.setPagination(
              page,
              res.count,
              res.list.length,
              "SALARY_PAGINATION",
              "SALARY_ERROR_COMPONENT"
            )
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" })
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setTitle()
          }
        })
    },
    sorting(e, sortBy) {
      var orderBy = "ASC"

      var className = "sorting_asc"
      var classes = e.target.className
      if (classes.includes("sorting_asc")) {
        className = "sorting_desc"
      }

      $(".sorting_asc").removeClass("sorting_asc")
      $(".sorting_desc").removeClass("sorting_desc")

      if (className == "sorting_asc") {
        $(e.target).addClass("sorting_asc").removeClass("sorting_desc")
        orderBy = "ASC"
      } else if (className == "sorting_desc") {
        $(e.target).addClass("sorting_desc").removeClass("sorting_asc")
        orderBy = "DESC"
      }
      this.getList(1, sortBy, orderBy)
    },
    getUserSummary() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "employee/" + this.id + "/summary",
        })
        .then((res) => {
          this.userSummary = res.info
          this.$storeService.commit("setTitle", this.userSummary.name)
          var branches = res?.info?.branches
          if (branches.length > 0) {
            var x_branch_id = localStorage.getItem("branch_id")
            branches.forEach((obj) => {
              if (obj.uuid == x_branch_id) {
                this.userSummary.city = obj.city
              }
            })
          }
          this.branchList = branches
          this.userSummary.total_due = res?.total_due ? res?.total_due : 0
          this.userSummary.total_advance_payment = res?.total_advance_payment
            ? res?.total_advance_payment
            : 0
          this.userSummary.total_due_incentive = res?.total_due_incentive
            ? res?.total_due_incentive
            : 0
          this.userSummary.total_due_salary = res?.total_due_salary
            ? res?.total_due_salary
            : 0
          this.userSummary.total_due_expense = res?.total_due_expense
            ? res?.total_due_expense
            : 0

          this.userSummary.total_balance = res?.total_balance ? res?.total_balance : 0
        })
        .catch(() => { })
    },
    onDeleteUser() {
      var method = "DELETE";
      this.$api
        .webRequest({
          _method: method,
          _action: "employee/" + this.employee_id + "/ledger/" + this.delete_id,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteRole.closeModal();
            this.getList(1);
            this.$toast.success(res.message, {
              position: "top-right",
            });
          }
        })
        .catch((e) => {
          this.$refs.deleteRole.removeLoader();
          this.$toast.error(e.message, {
            position: "top-right",
          });
        });
    },
    onExportReport() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "employee/" + this.id + "/ledgers/export",
          _body: this.filterObj,
          _buttonId: "cibil_report",
        })
        .then((res) => {
          if (res) {
            this.downloadPdf(res?.url, res?.file_name);
            this.$toast.success(res.message, { position: "top-right" });
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    downloadPdf(url, filename) {
      console.log(url, filename);
      var anchorElement = document.createElement("a");
      anchorElement.href = url;
      anchorElement.download = filename;
      anchorElement.target = "_blank";
      document.body.appendChild(anchorElement);
      console.log(anchorElement);
      anchorElement.click();
      document.body.removeChild(anchorElement);
    },
  },
}
</script>
