<template>
  <div>
    <div class="content-body">
      <div class="component-section no-code">
        <div class="container-fluid px-0">
          <div class="row">
            <div class="col-lg-12">
              <ul id="myTab" role="tablist" class="nav nav-tabs worker_tabs">
                <li class="nav-item">
                  <a
                    id="tab-1"
                    @click="changeTab('ACTIVE')"
                    data-toggle="tab"
                    href="#tab_1"
                    role="tab"
                    aria-controls="tab_1"
                    aria-selected="true"
                    class="nav-link active"
                    >Active</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    id="tab-2"
                    @click="changeTab('INACTIVE')"
                    data-toggle="tab"
                    href="#tab_2"
                    role="tab"
                    aria-controls="tab_2"
                    aria-selected="false"
                    class="nav-link"
                    >InActive</a
                  >
                </li>
              </ul>
              <div id="myTabContent" class="tab-content">
                <div class="worker_serch_warp">
                  <div class="row">
                    <div class="col-lg-3 mb-2">
                      <div class="search-form position-relative">
                        <input
                          type="text"
                          class="form-control"
                          v-model="filterObj.keyword"
                          v-on:blur="getList(1)"
                          placeholder="Search"
                        />
                        <div class="input-group-prepend position-absolute">
                          <button class="" type="button" id="button-addon1">
                            <img src="/static/img/search.svg" width="15" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 mb-2" v-if="hasFullAccess">
                      <div
                        class="add_btn_wrapper d-flex flex-row justify-content-end"
                      >
                        <Multiselect
                          ref="branch_id_multiselect"
                          name="branch_id"
                          mode="single"
                          trackBy="name"
                          label="name"
                          valueProp="branch_id"
                          placeholder="Select Branch"
                          class="form-control"
                          v-model="filterObj.branch_ids"
                          :options="branchList"
                          searchable="true"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6 mb-2">
                      <a @click="getList(1)" class="btn btn-brand-01 mr-2"
                        >Search
                      </a>
                      <a @click="reset()" class="btn btn-brand-03 mr-2"
                        >Reset
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  id="tab_1"
                  role="tabpanel"
                  aria-labelledby="tab-1"
                  class="tab-pane fade active show"
                >
                  <div class="card rounded-5">
                    
                    <div class="card-body p-3 p-lg-3">
                      <div class="row">
                        <div class="col-sm-12 col-lg-12 col-xl-12 d-flex justify-content-end pt-2 pb-2">
                          <button
                            v-if="access.can_print == 1"
                            @click="onExportDisbursalReport()"
                            class="btn btn-brand-01 mr-2 pb-2"
                            id="disbursement_report"
                          >
                            Export
                          </button>
                        </div>
                      </div>
                      <div class="table-responsive">
                        <table class="table table-primary mg-b-0">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                class="sorting"
                                @click="sorting($event, 'cin_number')"
                              >
                                CIN Number
                              </th>
                              <th
                                scope="col"
                                class="sorting"
                                @click="sorting($event, 'name')"
                              >
                                Name
                              </th>
                              <th
                                scope="col"
                                class="sorting"
                                @click="sorting($event, 'email')"
                              >
                                Email
                              </th>
                              <th
                                scope="col"
                                class="sorting"
                                @click="sorting($event, 'mobile_number')"
                              >
                                Mobile Number
                              </th>
                              <th
                                scope="col"
                                class="sorting"
                                @click="sorting($event, 'mobile_number')"
                              >
                                Branch
                              </th>
                              <th
                                scope="col"
                                class="sorting"
                                @click="sorting($event, 'created_at')"
                              >
                                Date
                              </th>
                              <th scope="col" class="">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="loader">
                              <td colspan="7" class="text-center">
                                <div
                                  class="spinner-border spinner-color"
                                  role="status"
                                ></div>
                              </td>
                            </tr>
                            <tr v-for="item in list" :key="item.id">
                              <td>{{ item.cin_number }}</td>
                              <td>{{ item.name }}</td>
                              <td>{{ item.email }}</td>
                              <td>
                                {{ item.mobile_number }}
                                <sup v-if="item?.has_app_installed === 1"
                                  ><i
                                    class="bi bi-patch-check text-success h6 fw-bold"
                                  ></i
                                ></sup>
                              </td>
                              <td>{{ item?.branch?.name }}</td>
                              <td>
                                {{
                                  $helperService.getFormattedDate(
                                    item.created_at
                                  )
                                }}
                              </td>
                              <td>
                                <a
                                  title="View"
                                  class="mx-2"
                                  style="cursor: pointer"
                                  @click="view(item, access.can_update)"
                                  ><img src="/static/img/eye-icon.svg" alt=""
                                /></a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <ErrorComponent
                          @retry="getList(1)"
                          ref="PENDING_ERROR_COMPONENT"
                        />
                      </div>
                      <div class="row">
                        <div class="col-lg-12 mt-5 text-center">
                          <div class="page-bottom-pagination text-right">
                            <Pagination
                              @page-change="pageChange"
                              @items-per-page-change="itemsPerPageChange"
                              ref="PENDING_PAGINATION"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="tab_2"
                  role="tabpanel"
                  aria-labelledby="tab-2"
                  class="tab-pane fade"
                >
                  <div class="card rounded-5">
                    <div class="card-body p-3 p-lg-3">
                      <div class="row">
                        <div class="col-sm-12 col-lg-12 col-xl-12 d-flex justify-content-end pt-2 pb-2">
                          <button
                            v-if="access.can_print == 1"
                            @click="onExportDisbursalReport()"
                            class="btn btn-brand-01 mr-2 pb-2"
                            id="disbursement_report"
                          >
                            Export
                          </button>
                        </div>
                      </div>
                      <div class="table-responsive">
                        <table class="table table-primary mg-b-0">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                class="sorting"
                                @click="sorting($event, 'cin_number')"
                              >
                                CIN Number
                              </th>
                              <th
                                scope="col"
                                class="sorting"
                                @click="sorting($event, 'name')"
                              >
                                Name
                              </th>
                              <th
                                scope="col"
                                class="sorting"
                                @click="sorting($event, 'email')"
                              >
                                Email
                              </th>
                              <th
                                scope="col"
                                class="sorting"
                                @click="sorting($event, 'mobile_number')"
                              >
                                Mobile Number
                              </th>
                              <th
                                scope="col"
                                class="sorting"
                                @click="sorting($event, 'mobile_number')"
                              >
                                Branch
                              </th>
                              <th
                                scope="col"
                                class="sorting"
                                @click="sorting($event, 'created_at')"
                              >
                                Date
                              </th>

                              <th scope="col" class="">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="loader">
                              <td colspan="7" class="text-center">
                                <div
                                  class="spinner-border spinner-color"
                                  role="status"
                                ></div>
                              </td>
                            </tr>
                            <tr v-else v-for="item in list" :key="item.id">
                              <td>{{ item.cin_number }}</td>
                              <td>{{ item.name }}</td>
                              <td>{{ item.email }}</td>
                              <td>
                                {{ item.mobile_number }}
                                <sub v-if="item?.has_app_installed === 1"
                                  ><i class="bi bi-patch-check"></i
                                ></sub>
                              </td>
                              <td>{{ item.branch.name }}</td>
                              <td>
                                {{
                                  $helperService.getFormattedDate(
                                    item.created_at
                                  )
                                }}
                              </td>
                              <td>
                                <a
                                  title="View"
                                  class="mx-2"
                                  style="cursor: pointer"
                                  @click="view(item, access.can_update)"
                                  ><img src="/static/img/eye-icon.svg" alt=""
                                /></a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <ErrorComponent
                          @retry="getList(1)"
                          ref="PENDING_FOR_VERIFICATION_ERROR_COMPONENT"
                        />
                      </div>
                      <div class="row">
                        <div class="col-lg-12 mt-5 text-center">
                          <div class="page-bottom-pagination text-right">
                            <Pagination
                              @page-change="pageChange"
                              @items-per-page-change="itemsPerPageChange"
                              ref="PENDING_FOR_VERIFICATION_PAGINATION"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import Pagination from "@/components/Pagination";
import ErrorComponent from "@/components/ErrorComponent";
import $ from "jquery";
export default {
  name: "CustomerManagement",
  components: {
    Pagination,
    ErrorComponent,
    Multiselect,
  },

  data() {
    return {
      loader: false,
      currentPage: 1,
      totalPage: 0,
      itemsPerPage: 10,
      list: [],
      access: {
        can_read: 1,
        can_create: 1,
        can_update: 1,
        can_print: 1,
        can_delete: 1,
      },
      filterObj: {
        keyword: "",
        offset: 1,
        branch_ids: "",
        sort_by: "created_at",
        sort_order: "desc",
        row_per_page: 10,
        level: "",
        status: "",
      },
      delete_id: "",
      statusOption: [
        {
          title: "Active",
          value: "ACTIVE",
        },
        {
          title: "Inactive",
          value: "INACTIVE",
        },
      ],
      customer_active_tab: "ACTIVE",
    };
  },
  computed: {
    branchList() {
      return this.$storeService.getters.getAllBranches;
    },
    hasFullAccess() {
      return this.$storeService.getters.getHasFullAccess;
    },
  },
  mounted() {
    this.customer_active_tab = localStorage.getItem("customer_active_tab")
      ? localStorage.getItem("customer_active_tab")
      : "ACTIVE";
    this.changeTab(this.customer_active_tab);
    this.$storeService.commit("setTitle", "Customer Management");
  },
  methods: {
    changeTab(tab) {
      this.customer_active_tab = tab;
      localStorage.setItem("customer_active_tab", tab);
      if (tab == "ACTIVE") {
        window.$("#tab-1").click();
        this.getList(1);
      } else if (tab == "INACTIVE") {
        window.$("#tab-2").click();
        this.getList(1);
      } else {
        window.$("#tab-1").click();
        this.getList(1);
      }
    },
    reset() {
      this.filterObj = {
        keyword: "",
        offset: 1,
        sort_by: "created_at",
        sort_order: "desc",
        row_per_page: 10,
        status: "",
        branch_ids: "",
      };
      this.getList(1);
    },
    view(item, can_update) {
      this.$router.push("/customer-detail/" + item.uuid + "/" + can_update);
    },
    pageChange(page) {
      this.getList(page);
    },

    itemsPerPageChange() {
      var tab = this.customer_active_tab;
      if (tab == "ACTIVE") {
        this.itemsPerPage = this.$refs.PENDING_PAGINATION.itemsPerPage;
      }
      if (tab == "INACTIVE") {
        this.itemsPerPage =
          this.$refs.PENDING_FOR_VERIFICATION_PAGINATION.itemsPerPage;
      }

      this.getList(1);
    },

    setPagination(page, count, listLength, paginationRef, errorCompRef) {
      if (page == 1) {
        if (count != null && count > 0) {
          if (this.$refs[paginationRef]) {
            this.$refs[paginationRef].setTotalCount(count);
          }
          if (this.$refs[errorCompRef]) {
            this.$refs[errorCompRef].setTitle(
              "Sorry, There is no record found.",
              false
            );
          }
        } else {
          if (this.$refs[paginationRef]) {
            this.$refs[paginationRef].setTotalCount(0);
          }
          if (this.$refs[errorCompRef]) {
            this.$refs[errorCompRef].setTitle(
              "Sorry, There is no record found.",
              true
            );
          }
        }
      }
      if (this.$refs[paginationRef]) {
        this.$refs[paginationRef].currentPage = this.currentPage;
      }
    },

    retry() {
      this.getList(1);
    },
    getList(page, sortBy, orderBy) {
      this.filterObj.status = this.customer_active_tab;
      this.filterObj.offset = page - 1;
      this.currentPage = page;
      this.filterObj.row_per_page = this.itemsPerPage;

      if (sortBy) {
        this.filterObj.sort_by = sortBy;
      }
      if (orderBy) {
        this.filterObj.sort_order = orderBy;
      }
      this.list = [];
      this.loader = true;

      var method = "GET";

      this.$api
        .webRequest({
          _method: method,
          _action: "customers",
          _body: this.filterObj,
        })
        .then((res) => {
          if (res.access.can_read == 0) {
            return this.$router.go(-1);
          }
          this.loader = false;
          this.list = res.list;
          this.access = res.access;
          var tab = this.customer_active_tab;
          if (tab == "ACTIVE") {
            this.setPagination(
              page,
              res.count,
              res.list.length,
              "PENDING_PAGINATION",
              "PENDING_ERROR_COMPONENT"
            );
          } else if (tab == "INACTIVE") {
            this.setPagination(
              page,
              res.count,
              res.list.length,
              "PENDING_FOR_VERIFICATION_PAGINATION",
              "PENDING_FOR_VERIFICATION_ERROR_COMPONENT"
            );
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setTitle();
          }
        });
    },
    sorting(e, sortBy) {
      var orderBy = "ASC";

      var className = "sorting_asc";
      var classes = e.target.className;
      if (classes.includes("sorting_asc")) {
        className = "sorting_desc";
      }

      $(".sorting_asc").removeClass("sorting_asc");
      $(".sorting_desc").removeClass("sorting_desc");

      if (className == "sorting_asc") {
        $(e.target).addClass("sorting_asc").removeClass("sorting_desc");
        orderBy = "ASC";
      } else if (className == "sorting_desc") {
        $(e.target).addClass("sorting_desc").removeClass("sorting_asc");
        orderBy = "DESC";
      }
      this.getList(1, sortBy, orderBy);
    },
    onExportDisbursalReport() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "export/customers",
          _body: this.filterObj,
          _buttonId: "disbursement_report",
        })
        .then((res) => {
          if (res) {
            this.downloadPdf(res?.file_url, res?.file_name)
            this.$toast.success(res.message, {position: "top-right"})
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, {position: "top-right"})
        })
    },
    downloadPdf(url, filename) {
      if (url == null) {
        window.$("#showDownloadAlert").modal("show")
      } else {
        console.log(url, filename)
        var anchorElement = document.createElement("a")
        anchorElement.href = url
        anchorElement.download = filename
        anchorElement.target = "_blank"
        document.body.appendChild(anchorElement)
        console.log(anchorElement)
        anchorElement.click()
        document.body.removeChild(anchorElement)
      }
    },
  },
};
</script>
