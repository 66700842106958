<template>
  <div>
    <div class="content-body">
      <div class="component-section no-code">
        <div class="row">
          <div class="container-fluid">
            <div class="worker_serch_warp">
              <div class="row">
                <div class="col-lg-3 mb-2">
                  <div class="search-form position-relative">
                    <input
                      type="text"
                      class="form-control"
                      v-model="filterObj.keyword"
                       v-on:blur="getList(1)"
                      placeholder="Search"
                    />
                    <div class="input-group-prepend position-absolute">
                      <button class="" type="button" id="button-addon1">
                        <img src="/static/img/search.svg" width="15" />
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 mb-2" v-if="hasFullAccess">
                  <div class="add_btn_wrapper d-flex flex-row justify-content-end">
                    <Multiselect
                      ref="branch_id_multiselect"
                      name="branch_id"
                      mode="single"
                      trackBy="name"
                      label="name"
                      valueProp="branch_id"
                      placeholder="Select Branch"
                      class="form-control"
                      v-model="filterObj.branch_ids"
                      :options="branchList"
                      searchable="true"
                    />
                  </div>
                </div>
                <!-- <div class="col-lg-3 mb-2">
                  <div
                    class="add_btn_wrapper d-flex flex-row justify-content-end"
                  >
                    <Multiselect
                      ref="loan_status_multiselect"
                      name="loan_status"
                      mode="single"
                      trackBy="title"
                      label="title"
                      valueProp="value"
                      placeholder="Select disbursed status"
                      class="form-control"
                      v-model="filterObj.loan_status"
                      :options="disbursedList"
                      searchable="true"
                    />
                  </div>
                </div> -->
                <div class="col-lg-3 mb-2">
                  <div class="add_btn_wrapper d-flex flex-row justify-content-end">
                    <Multiselect
                      ref="type_id_multiselect"
                      name="type_id"
                      mode="single"
                      trackBy="name"
                      label="title"
                      valueProp="id"
                      placeholder="Select Loan Type"
                      class="form-control"
                      v-model="filterObj.loan_type_id"
                      :options="loanTypes"
                      searchable="true"
                    />
                  </div>
                </div>
                <!-- <div class="col-lg-3 mb-2">
                  <VueDatePicker
                    placeholder="Select Disbursed Date Range"
                    :format="'dd/MM/yyyy'"
                    style="height: 46px !important"
                    v-model="date"
                    range
                    :type="boolean"
                    :default="true"
                    :enable-time-picker="false"
                  >
                  </VueDatePicker>
                </div>
                <div class="col-lg-3 mb-2">
                  <VueDatePicker
                    placeholder="Select Cycle Date Range"
                    :format="'dd/MM/yyyy'"
                    style="height: 46px !important"
                    v-model="cycle_date"
                    range
                    :type="boolean"
                    :default="true"
                    :enable-time-picker="false"
                  >
                  </VueDatePicker>
                </div> -->
                <div class="col-lg-3 mb-2">
                  <a @click="getList(1)" class="btn btn-brand-01 mr-2">Search </a>
                  <a @click="reset()" class="btn btn-brand-03 mr-2">Reset </a>
                </div>
              </div>
            </div>
            <div class="card rounded-5">
              <div class="card-body p-3 p-lg-3">
                <div class="row">
                  <div class="col-lg-12 d-flex flex-row justify-content-end pb-3">
                    <button
                      v-if="access.can_print == 1"
                      @click="onExportDisbursalReport()"
                      class="btn btn-brand-01 mr-2"
                      id="disbursement_report"
                    >
                      Export
                    </button>
                  </div>
                </div>
                <div class="table-responsive">
                  <table class="table table-primary mg-b-0">
                    <thead>
                      <tr>
                        <th scope="col">Loan ID</th>
                        <th
                          scope="col"
                          class="sorting"
                          @click="sorting($event, 'loan_applications.applicant_name')"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          class="sorting"
                          @click="sorting($event, 'branches.name')"
                        >
                          Branch
                        </th>
                        <th
                          scope="col"
                          class="sorting"
                          @click="sorting($event, 'loan_applications.disbursed_at')"
                        >
                          Disbursement Date
                        </th>
                        <th scope="col">Cycle Date</th>
                        <!-- <th scope="col">
                          Requested Amount
                        </th> -->
                        <!-- <th scope="col">Total Loan Amount</th>
                        <th scope="col">Disbursed Amount</th>
                        <th scope="col">Processing Fee</th> -->
                        <!-- <th scope="col">GST</th> -->

                        <th scope="col">Total Due Amount</th>
                        <th scope="col"># of Installment Dues</th>
                        <!-- <th scope="col">Renew Eligibility</th> -->
                        <th scope="col">Status</th>
                        <th scope="col">Last Followup By</th>
                        <th scope="col">Last Followup</th>
                        <th scope="col">Last Followup Date</th>
                        <th scope="col" class="">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="loader">
                        <td colspan="12" class="text-center">
                          <div class="spinner-border spinner-color" role="status"></div>
                        </td>
                      </tr>
                      <tr v-for="item in list" :key="item.id">
                        <td>
                          {{ item?.application_id
                          }}<sub
                            class="font-weight-bolder text-danger"
                            v-if="item?.parent_loan_id != null"
                            >&nbsp;(Renew)</sub
                          >
                        </td>
                        <td>
                          {{ item?.applicant_name }}
                          <br />
                          <span>({{ item?.mobile_number }}) <sup v-if="item?.user?.has_app_installed === 1"><i class="bi bi-patch-check text-success h6 fw-bold"></i></sup></span>
                        </td>
                        <td>{{ item?.branch?.name }}</td>
                        <td>
                          {{ $helperService.getFormattedDateOnly(item?.disbursed_at) }}
                        </td>
                        <td>
                          {{
                            $helperService.getFormattedDateOnly(item?.cycle_start_date)
                          }}
                        </td>
                        <!-- <td>
                          {{ $helperService.getFormattedCurrency(item.loan_amount) }}
                        </td> -->
                        <!-- <td>
                          {{
                            $helperService.getFormattedCurrency(
                              item?.total_loan_amount
                            )
                          }}
                        </td>
                        <td>
                          {{
                            $helperService.getFormattedCurrency(
                              item?.disbursed_loan_amount
                            )
                          }}
                        </td>
                        <td>
                          {{
                            $helperService.getFormattedCurrency(
                              item?.processing_fee
                            )
                          }}
                        </td>
                        <td>
                          {{
                            $helperService.getFormattedCurrency(
                              item?.gst
                            )
                          }}
                        </td> -->

                        <td>
                          {{
                            $helperService.getFormattedCurrency(item?.total_due_amount)
                          }}
                        </td>
                        <td>{{ item?.due_installments }}</td>
                        <!-- <td>
                          <a
                            title="Eligible"
                            class="mx-2 text-center"
                            v-if="item.is_renew_eligible == true"
                            style="cursor: pointer"
                            ><img
                              src="/static/img/eligible.png"
                              alt=""
                              style="width: 22px"
                          /></a>
                          <a
                            title="Not Eligible"
                            class="mx-2 text-center"
                            v-else
                            style="cursor: pointer"
                            ><img
                              src="/static/img/uneligible.png"
                              style="width: 20px"
                              alt=""
                          /></a>
                        </td> -->
                        <td v-if="item.status == 'LOAN_CLOSED'">
                          <span class="text-danger"> Closed </span>
                        </td>
                        <td v-else><span class="text-success">Active</span></td>
                        <td>{{ item?.recent_note?.added_by?.name }}</td>
                        <td>{{ item?.recent_note?.description }}</td>
                        <td>
                          <span>
                            {{
                              $helperService.getFormattedDate(
                                item?.recent_note?.created_at
                              )
                            }}</span
                          >
                        </td>
                        <td>
                          <a
                            title="View"
                            class="mx-2"
                            style="cursor: pointer"
                            @click="view(item, access.can_read)"
                            ><img src="/static/img/eye-icon.svg" alt=""
                          /></a>
                          <a
                            title="Add Notes"
                            class="mx-2"
                            style="cursor: pointer"
                            v-if="access.can_read == 1"
                            @click="OpenNotesModel(item)"
                            ><img src="/static/img/comment-icon.svg" alt=""
                          /></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <ErrorComponent @retry="getList(1)" ref="errorComponent" />
                </div>
                <div class="row">
                  <div class="col-lg-12 mt-5 text-center">
                    <div class="page-bottom-pagination text-right">
                      <Pagination
                        @page-change="pageChange"
                        @items-per-page-change="itemsPerPageChange"
                        ref="rolePagination"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ConfirmationModal @remove="onDeleteAssetManagement" ref="deleteAssetManagement" />
    <div
      class="modal fade wb-modal-wrapper"
      id="showDownloadAlert"
      tabindex="-1"
      role="dialog"
      aria-labelledby="showDownloadAlert"
      aria-hidden="true"
      style="pointer-events: none"
    >
      <div class="modal-dialog modal-md modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
          <a
            class="close mr-3 mt-3 text-right"
            @click="closeDownloadAlertPopup()"
            aria-label="Close"
          >
            <img src="/static/img/close-icon.svg" width="20" />
          </a>
          <div class="modal-header justify-content-center">
            <h5 class="modal-title font-22">
              <span>Download Alert</span>
            </h5>
          </div>
          <div class="modal-body form-style pb-0 px-lg-5">
            <div class="row">
              <div class="col-lg-12">
                Loan Agreement/Sanction Letter or Loan schedule will be available soon.
              </div>
            </div>
          </div>
          <div class="modal-footer border-0 justify-content-center mb-3">
            <button
              id="cancel-btn"
              @click="closeDownloadAlertPopup()"
              type="button"
              class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade wb-modal-wrapper"
      id="onOpenNotesModel"
      tabindex="-1"
      role="dialog"
      aria-labelledby="onOpenNotesModel"
      aria-hidden="true"
      style="pointer-events: none"
    >
      <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
          <a
            class="close mr-3 mt-3 text-right"
            @click="closeNotesModel()"
            aria-label="Close"
          >
            <img src="/static/img/close-icon.svg" width="20" />
          </a>
          <Form
            @submit="onAddPendingNotes"
            class="columns column is-multiline is-12"
            ref="onAddPendingNotes"
          >
            <div class="modal-header justify-content-center">
              <h5 class="modal-title font-22">
                <span>Followup Notes</span>
              </h5>
            </div>

            <div class="modal-body form-style pb-0 px-lg-5">
              <div class="row">
                <div class="col-xl-12">
                  <label>Notes<span class="text-danger">*</span> </label>
                  <Field
                    v-slot="{notes_feild}"
                    name="loan_notes"
                    rules="required:followup note,true"
                    :validateOnInput="true"
                    v-model="loan_notes"
                  >
                    <textarea
                      v-bind="notes_feild"
                      rows="5"
                      cols="5"
                      valueProp="id"
                      placeholder="Enter followup notes.."
                      class="form-control"
                      v-model="loan_notes"
                    />
                  </Field>
                  <ErrorMessage name="loan_notes" class="validation-msg" />
                </div>
              </div>
            </div>
            <div class="modal-footer border-0 justify-content-center mb-3">
              <button
                id="cancel-btn"
                @click="closeNotesModel()"
                type="button"
                class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-brand-01 wb-save-btn" id="loan_notes">
                Submit
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css"
import Pagination from "@/components/Pagination"
import ErrorComponent from "@/components/ErrorComponent"
import $ from "jquery"
import ConfirmationModal from "@/components/ConfirmationModal.vue"
import Multiselect from "@vueform/multiselect"
import "@vueform/multiselect/themes/default.css"
import {Form, Field, ErrorMessage} from "vee-validate"
import moment from "moment"
export default {
  name: "LoanDisbursed",
  components: {
    Pagination,
    ErrorComponent,
    ConfirmationModal,
    Multiselect,
    Form,
    ErrorMessage,
    Field,
  },

  data() {
    return {
      loader: false,
      currentPage: 1,
      totalPage: 0,
      itemsPerPage: 10,
      list: [],
      loanTypes: [],
      access: {
        can_read: 1,
        can_create: 1,
        can_update: 1,
        can_print: 1,
        can_delete: 1,
      },
      total_loan_disbursed: "",
      filterObj: {
        keyword: "",
        come_from: "RENEW_LOAN",
        offset: 1,
        sort_by: "loan_applications.created_at",
        sort_order: "desc",
        row_per_page: 10,
        status: "",
        disbursed_from_date: "",
        disbursed_to_date: "",
        loan_status: "ALL",
        branch_ids: "",
        loan_type_id: "",
        application_type: "ALL",
        cycle_from_date: "",
        cycle_to_date: "",
      },
      cycle_date: "",
      delete_id: "",
      date: "",
      disbursedList: [
        {
          title: "All Applications",
          value: "ALL",
        },
        {
          title: "Active",
          value: "ACTIVE",
        },
        {
          title: "Closed",
          value: "CLOSED",
        },
      ],
      loanApplicationTypes: [
        {
          title: "All (New/Renew Application)",
          value: "ALL",
        },
        {
          title: "New",
          value: "NEW",
        },
        {
          title: "Renew",
          value: "RENEW",
        },
      ],
      total_loan_disbursed_count: 0,
      loan_uuid: "",
      loan_notes: "",
    }
  },
  computed: {
    branchList() {
      return this.$storeService.getters.getAllBranches
    },
    hasFullAccess() {
      return this.$storeService.getters.getHasFullAccess
    },
    hasFilterOption() {
      return this.$storeService.getters.getLoanDisbursedObj
    },
  },
  mounted() {
    this.onCheckUserTire()
    this.$storeService.commit("setTitle", "Renew Loan Eligible")
    if (this.hasFilterOption) {
      this.filterObj.branch_ids = this.hasFilterOption?.branch_id
        ? this.hasFilterOption?.branch_id
        : ""
      this.filterObj.application_type = this.hasFilterOption?.application_type
        ? this.hasFilterOption?.application_type
        : "ALL"
      this.filterObj.loan_type_id = this.hasFilterOption?.loan_type_id
        ? this.hasFilterOption?.loan_type_id
        : ""
    }
    this.getList(1)
  },
  methods: {
    OpenNotesModel(item) {
      this.loan_uuid = item.uuid
      window.$("#onOpenNotesModel").modal("show")
    },
    closeNotesModel() {
      this.loan_notes = ""
      this.loan_uuid = null
      window.$("#onOpenNotesModel").modal("hide")
    },
    reset() {
      this.filterObj = {
        keyword: "",
        offset: 1,
        sort_by: "created_at",
        sort_order: "desc",
        come_from: "RENEW_LOAN",
        row_per_page: 10,
        level: "",
        status: "",
        branch_ids: "",
        disbursed_from_date: "",
        disbursed_to_date: "",
        loan_status: "ALL",
        loan_type_id: "",
        application_type: "ALL",
        cycle_from_date: "",
        cycle_to_date: "",
      }
      this.cycle_date = ""
      this.date = ""
      this.getList(1)
    },
    view(item) {
      this.$router.push("/view-renew/" + item.uuid)
    },
    pageChange(page) {
      this.getList(page)
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.rolePagination.itemsPerPage
      this.getList(1)
    },

    retry() {
      this.getList(1)
    },
    getList(page, sortBy, orderBy) {
      this.filterObj.offset = page - 1
      this.currentPage = page
      this.filterObj.row_per_page = this.itemsPerPage

      if (sortBy) {
        this.filterObj.sort_by = sortBy
      }
      if (orderBy) {
        this.filterObj.sort_order = orderBy
      }

      if (!this.filterObj.branch_ids) {
        this.filterObj.branch_ids = ""
      }

      if (this.date) {
        this.filterObj.disbursed_from_date = moment(String(this.date[0])).format(
          "Y-MM-DD"
        )
        this.filterObj.disbursed_to_date = moment(String(this.date[1])).format("Y-MM-DD")
      } else {
        this.filterObj.disbursed_from_date = ""
        this.filterObj.disbursed_to_date = ""
      }
      if (this.cycle_date) {
        this.filterObj.cycle_from_date = moment(String(this.cycle_date[0])).format(
          "Y-MM-DD"
        )
        this.filterObj.cycle_to_date = moment(String(this.cycle_date[1])).format(
          "Y-MM-DD"
        )
      } else {
        this.filterObj.cycle_from_date = ""
        this.filterObj.cycle_to_date = ""
      }

      this.list = []
      this.loader = true
      var method = "GET"
      this.$api
        .webRequest({
          _method: method,
          _action: "/disbursed/loans",
          _body: this.filterObj,
        })
        .then((res) => {
          this.loader = false
          if (res.access.can_read == 0) {
            return this.$router.go(-1)
          }
          this.list = res.list
          this.access = res?.access
          this.total_loan_disbursed = res?.total_amount ? res?.total_amount : 0
          this.total_loan_disbursed_count = res?.count ? res?.count : 0
          if (page == 1) {
            if (res.count != null && res.count > 0) {
              if (this.$refs.rolePagination) {
                this.$refs.rolePagination.setTotalCount(res.count)
              }
            } else {
              if (this.$refs.rolePagination) {
                this.$refs.rolePagination.setTotalCount(0)
              }
            }
          }
          if (!res.list || res.list.length <= 0) {
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setTitle("Sorry, There is no record found.", true)
            }
          } else {
            if (this.$refs.errorComponent) {
              if (this.$refs.errorComponent.isShowMessage()) {
                this.$refs.errorComponent.setTitle()
              }
            }
          }
          if (this.$refs.rolePagination) {
            this.$refs.rolePagination.currentPage = this.currentPage
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, {position: "top-right"})
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setTitle()
          }
        })
    },
    sorting(e, sortBy) {
      var orderBy = "ASC"

      var className = "sorting_asc"
      var classes = e.target.className
      if (classes.includes("sorting_asc")) {
        className = "sorting_desc"
      }

      $(".sorting_asc").removeClass("sorting_asc")
      $(".sorting_desc").removeClass("sorting_desc")

      if (className == "sorting_asc") {
        $(e.target).addClass("sorting_asc").removeClass("sorting_desc")
        orderBy = "ASC"
      } else if (className == "sorting_desc") {
        $(e.target).addClass("sorting_desc").removeClass("sorting_asc")
        orderBy = "DESC"
      }
      this.getList(1, sortBy, orderBy)
    },

    onCheckUserTire() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "select/loan-type",
        })
        .then((res) => {
          for (var i = 0; i < res.list.length; i++) {
            var obj = {
              title:
                res.list[i].title +
                " (" +
                this.$helperService.getTitleCase(res.list[i].type) +
                ")" +
                " - " +
                this.$helperService.getTitleCase(res.list[i].level),
              id: res.list[i].id,
            }
            this.loanTypes.push(obj)
          }
        })
        .catch(() => {})
    },
    onExportReport() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "report/cibil",
          _buttonId: "cibil_report",
        })
        .then((res) => {
          if (res) {
            this.downloadPdf(res?.url, res?.file_name)
            this.$toast.success(res.message, {position: "top-right"})
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, {position: "top-right"})
        })
    },
    onExportDisbursalReport() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "get-loan-disbursed-export",
          _body: this.filterObj,
          _buttonId: "disbursement_report",
        })
        .then((res) => {
          if (res) {
            this.downloadPdf(res?.url, res?.file_name)
            this.$toast.success(res.message, {position: "top-right"})
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, {position: "top-right"})
        })
    },
    downloadPdf(url, filename) {
      if (url == null) {
        window.$("#showDownloadAlert").modal("show")
      } else {
        console.log(url, filename)
        var anchorElement = document.createElement("a")
        anchorElement.href = url
        anchorElement.download = filename
        anchorElement.target = "_blank"
        document.body.appendChild(anchorElement)
        console.log(anchorElement)
        anchorElement.click()
        document.body.removeChild(anchorElement)
      }
    },
    closeDownloadAlertPopup() {
      window.$("#showDownloadAlert").modal("hide")
    },
    onAddPendingNotes() {
      var method = "PUT"
      var action = "loan/" + this.loan_uuid + "/note"
      this.$api
        .webRequest({
          _method: method,
          _action: action,
          _body: {description: this.loan_notes},
          _buttonId: "loan_notes",
          _hide_loader: true,
        })
        .then((res) => {
          this.closeNotesModel()
          this.$toast.success(res.message, {position: "top-right"})
        })
        .catch((e) => {
          this.$toast.error(e.message, {position: "top-right"})
        })
    },
  },
}
</script>
